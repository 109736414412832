import React from "react";
import InputLabel from "@mui/material/InputLabel";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function FloatingLabelDropdown({
  name,
  value,
  handleChange,
  label,
  error,
  disabled,
  children,
  inputProps,
  labelId,
  multiple = false,
  style,
  labelStyle,
}) {
  return (
    <div style={style}>
      <FormControl sx={{ minWidth: 120, width: "100%" }}>
        <InputLabel id={label} style={labelStyle}>
          {label}
        </InputLabel>
        <Select
          id="demo-simple-select-helper"
          labelId={label}
          name={name}
          label={label}
          inputProps={inputProps}
          multiple={multiple}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          {children}
        </Select>
        {error ? (
          <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}
