import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function ActivityLoader() {
  return (
    <button className="buttonX">
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </button>
  );
}
