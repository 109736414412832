import React from "react";
import { Modal } from "react-bootstrap";

export default function HeaderAPIKeyModal(props) {
  return (
    <Modal
      size="md"
      centered
      show={props.show}
      onHide={() => props.handleClose()}
    >
      <Modal.Header closeButton>
        <Modal.Title>API Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0 text-wrap">
          {props.apiKey !== ""
            ? props.apiKey
            : "Something went wrong, please load again..."}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="buttonX" onClick={() => props.handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
