import React from "react";
import "./CommonCard.scss";

export default function CommonCard(props) {
  return (
    <div
      className={
        props.isForm
          ? `form-card ${props.className}`
          : `common-card ${props.className}`
      }
    >
      {props.children}
    </div>
  );
}
