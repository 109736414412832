import React, { useContext, useState } from "react";
import Aside from "./Aside";
import Main from "./Main";
import ThemeContext from "../../themes/ThemeContext";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function Layout(props) {
  const [rtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [active, setActive] = useState(true);

  const { theme } = useContext(ThemeContext);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const navigate = (item) => {
    setActive(!active);
    props.history.push(item);
  };

  return (
    <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
      <ToastContainer theme={theme} />

      <Aside
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        navigate={navigate}
        active={active}
        heading={props.title}
        crumbs={props.crumbs}
        history={props.history}
      />

      <Main
        handleToggleSidebar={handleToggleSidebar}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleCollapsedChange={handleCollapsedChange}
        crumbs={props.crumbs}
        history={props.history}
      >
        {props.children}
      </Main>
    </div>
  );
}

// export default withRouter(Layout);
