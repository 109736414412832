import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CommonCard from "../../components/CommonCard/CommonCard";
import Layout from "../../components/SideBar/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { CREATE_ACCOUNT_PATH, ACCOUNT_PATH } from "../../Router/PathConst";
import { get, post, put } from "../../utils/apiMethods";
import ApiConstant from "../../utils/apiConstant";
import { checkProps, createMessage } from "../../utils/common";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import ActivityLoader from "../../components/Loader/ActivityLoader";
import FloatingLabelDropdown from "../../components/FloatingLabelDropdown/FloatingLabelDropdown";
import { toast } from "react-toastify";

export default function AddAndUpdateAccount(props) {
  const [type, setType] = useState("add");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAPILoading, setIsAPILoading] = useState(true);
  // const [assignedRoles, setAssignedRoles] = useState([]);

  const [hiddenProps] = useState([
    "bookmarks",
    "sorting",
    "ENABLE_API_ACCESS",
    "ENCRYPTED_AES_KEY",
    "API_Key",
    "ACCESS_TOKEN",
  ]);

  const [propsArray, setPropsArray] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  const crumbs = [
    {
      title: "Accounts",
      path: ACCOUNT_PATH,
      active: false,
    },
    {
      title: type === "edit" ? "Update Account" : "Create Account",
      path: CREATE_ACCOUNT_PATH,
      active: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    accountName: Yup.string().required("Please enter account name"),
    email: Yup.string().email("Invalid email").required("Email Id is Required"),
    subdomain: Yup.string()
      .matches(
        /^[a-z][a-z0-9-]{0,31}$/,
        "Invalid subdomain name. Use lowercase letters, digits, and hyphens only. Must start with a letter and not be longer than 32 characters."
      )
      .required("Subdomain is required"),
  });

  const formik = useFormik({
    initialValues: {
      accountName: type === "edit" ? data.account_name : "",
      email: type === "edit" ? data.email : "",
      userName: type === "edit" ? data.username : "",
      subdomain: type === "edit" ? data.subdomain : "",
      status: type === "edit" ? data.status : "available",
      legacyMode: type === "edit" ? data?.props?.LEGACY_MODE : false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
  });

  useEffect(() => {
    if (props.match.params.type === "create") {
      setType(props.match.params.type);
    } else {
      setType("edit");
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getAccountData = async () => {
      const id = props.match.params.type;

      const URL = `${ApiConstant.GET_ACCOUNT}/${id}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);

        if (response.props && response.props !== undefined) {
          objToArray(response.props);
        }
        setLoading(false);
      }
    };

    if (props.match.params.type !== "create") {
      getAccountData();
    }
  }, [props.match.params.type]);

  const getAccountData = async (ID) => {
    const id = props.match.params.type;

    const URL = `${ApiConstant.GET_ACCOUNT}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      setData(response);
      setIsAPILoading(true);
      if (response.props && response.props !== undefined) {
        objToArray(response.props);
      }
    }
  };

  const onSubmitCall = async (values) => {
    setIsAPILoading(false);

    var newProps = [];

    for (const key in data.props) {
      if (hiddenProps.includes(key) && key !== "") {
        const obj = {
          id: key,
          name: data.props[key],
        };

        newProps.push(obj);
      }
    }

    if (type === "edit") {
      const id = props.match.params.type;

      const allProperties = arrayToObj([...propsArray, ...newProps]);

      const bodyObject = {
        status: values.status,
        legacy_mode: values.legacyMode,
      };

      const URL = `${ApiConstant.UPDATE_ACCOUNT}/${id}`;

      const response = await put({
        url: URL,
        reqObj: bodyObject,
        type: "v2",
      });

      if (response.status === 200) {
        createMessage(200, "Account updated");

        setTimeout(() => {
          getAccountData();
        }, 1000);
      } else if (response.status === 400) {
        createMessage(400, response.message);
      } else {
        createMessage(response.status);
      }
      setIsAPILoading(true);
    } else {
      const bodyObject = {
        account_name: values.accountName,
        email: values.email,
        username: values.userName,
        subdomain: values.subdomain,
        legacy_mode: values.legacyMode,
      };

      const URL = ApiConstant.ADD_ACCOUNT;

      try {
        const response = await post({
          url: URL,
          obj: bodyObject,
          type: "v2",
        });
        const data = await response.json();

        if (response.status === 200) {
          createMessage(200, "Account created");
          setTimeout(() => {
            props.history.push(ACCOUNT_PATH);
          }, 1000);
        } else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
          });
        }
        setIsAPILoading(true);
      } catch (error) {
        createMessage(error);
        setIsAPILoading(true);
      }
    }
  };

  // const assignRoles = async (id) => {
  //   const response = await put({
  //     url: `${ADD_ACCOUNT}/${id}/roles`,
  //     reqObj: assignedRoles,
  //     type: "User",
  //   });
  //   if (response === 201) {
  //     createMessage(200, "Role assigned to user");
  //   } else {
  //     createMessage(response);
  //   }

  //   setTimeout(() => {
  //     if (type === "edit") {
  //       getAccountData(id);
  //     } else {
  //       props.history.push(ACCOUNT_PATH);
  //     }
  //   }, 1000);
  // };

  const arrayToObj = (array) => {
    let obj = {};
    if (array && array.length > 0) {
      obj = array.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item.name,
        }),
        {}
      );
    }

    return obj;
  };

  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: "",
      name: "",
    };
    array.push(obj);
    setPropsArray(array);
  };

  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };

  const handleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === "id") {
      array[index].id = e.target.value;
    } else {
      array[index].name = e.target.value;
    }
    setPropsArray(array);
  };

  const objToArray = (properties) => {
    if (properties !== undefined) {
      let array = [];
      let propArray = Object.keys(properties);

      for (let i = 0; i < propArray.length; i++) {
        const obj = {
          id: propArray[i],
          name: properties[propArray[i]],
        };
        if (!hiddenProps?.includes(propArray[i])) {
          array.push(obj);
        }
      }
      setPropsArray(array);
    }
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="add-edit-section">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left">
              {type === "edit" ? "Update Account" : "Create Account"}
            </h4>
          </div>

          {type === "edit" && loading ? (
            <SkeletonLoader height={30} time={1} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper">
                <div>
                  <TextField
                    autoComplete="off"
                    inputProps={{ "data-testid": "accountName-Input" }}
                    style={{ width: "100%" }}
                    variant="outlined"
                    id="accountName"
                    name="accountName"
                    label="Account Name"
                    value={formik.values.accountName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.accountName &&
                      Boolean(formik.errors.accountName)
                    }
                    helperText={
                      formik.touched.accountName && formik.errors.accountName
                    }
                    disabled={type === "edit" ? true : false}
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    autoComplete="off"
                    inputProps={{ "data-testid": "email-Input" }}
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled={type === "edit" ? true : false}
                  />
                </div>
                {type === "edit" && (
                  <div className="mt-4">
                    <TextField
                      autoComplete="off"
                      inputProps={{ "data-testid": "username-Input" }}
                      style={{ width: "100%" }}
                      variant="outlined"
                      id="userName"
                      name="userName"
                      label="User Name"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.userName &&
                        Boolean(formik.errors.userName)
                      }
                      helperText={
                        formik.touched.userName && formik.errors.userName
                      }
                      disabled={type === "edit" ? true : false}
                    />
                  </div>
                )}
                <div className="mt-4">
                  <TextField
                    autoComplete="off"
                    inputProps={{ "data-testid": "subdomain-Input" }}
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    name="subdomain"
                    label="subdomain"
                    value={formik.values.subdomain}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subdomain &&
                      Boolean(formik.errors.subdomain)
                    }
                    helperText={
                      formik.touched.subdomain && formik.errors.subdomain
                    }
                    disabled={type === "edit" ? true : false}
                  />
                </div>
                {type === "edit" ? (
                  <div className="mt-4">
                    <FloatingLabelDropdown
                      handleChange={formik.handleChange}
                      value={formik.values.status}
                      name="status"
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      label="Status"
                      inputProps={{ "data-testid": "status-select" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="available">Active</MenuItem>
                      <MenuItem value="deactivated">Deactivate</MenuItem>
                    </FloatingLabelDropdown>
                  </div>
                ) : null}

                <div className="mt-3">
                  <Checkbox
                    className="mui-checkbox"
                    id="legacyMode"
                    name="legacyMode"
                    color="primary"
                    checked={formik.values.legacyMode}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="legacyMode">Enable legacy mode</label>
                </div>

                {false && (
                  <div className="props-wrapper mt-3">
                    <p className="prop-label">
                      Properties
                      <span>
                        <i
                          className={
                            propsArray.length === 0
                              ? "bi bi-plus-circle add-icon ms-2 text-primary cursor-pointer h4"
                              : "bi bi-plus-circle vis-hidden"
                          }
                          onClick={
                            propsArray.length === 0 ? () => addProps() : null
                          }
                        />
                      </span>
                    </p>

                    <div className="props-box">
                      {propsArray &&
                        propsArray.map((item, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center mt-2"
                              key={i}
                            >
                              <div className="inputs">
                                <input
                                  className="form-control id-input"
                                  data-testid={`id-prop ${i}`}
                                  placeholder="name"
                                  value={item.id}
                                  onChange={(e) =>
                                    handleChangeProps(e, i, "id")
                                  }
                                />
                              </div>
                              <div className="inputs">
                                <input
                                  className="form-control id-input"
                                  data-testid={`name-prop ${i}`}
                                  placeholder="value"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleChangeProps(e, i, "name")
                                  }
                                />
                              </div>
                              <div
                                className="d-flex justify-content-end"
                                style={{ width: "80px" }}
                              >
                                <i
                                  className={"bi bi-dash-circle close-icon"}
                                  data-testid={`delete-prop ${i}`}
                                  onClick={() => deleteProps(i)}
                                />
                                <i
                                  data-testid={`add-prop ${i}`}
                                  onClick={
                                    i === propsArray.length
                                      ? null
                                      : () => addProps()
                                  }
                                  className={
                                    i === propsArray.length - 1
                                      ? "bi bi-plus-circle add-icon"
                                      : "bi bi-plus-circle vis-hidden"
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                {isAPILoading ? (
                  <>
                    <button className="buttonX" type="submit">
                      {type === "edit" ? "Update Account" : "Create Account"}
                    </button>
                    <button
                      className="buttonX white"
                      type="button"
                      onClick={() =>
                        type === "edit"
                          ? props.history.goBack()
                          : props.history.push(ACCOUNT_PATH)
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </form>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
