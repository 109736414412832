import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { EnhancedTableHead } from "../../../components/TableComponent/TableWithSelection";
import {
  getComparator,
  stableSort,
} from "../../../components/TableComponent/TableComponent";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";

export default class PermissionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "name",
      selected: [],
      page: 0,
      rowsPerPage: 5,
      loading: false,
      isDone: true,
      permissionList: [],
      assignPermissionModal: false,
      selectedPermissions: [],
      permissionUUID: [],
    };
  }

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.props.permissionList.map((n) => n.id);

      this.setState({
        selected: newSelecteds,
        selectedPermissions: newSelecteds,
      });
      this.props.onPermissionSelect(newSelecteds);

      return;
    }
    this.props.onPermissionSelect([]);
    this.setState({ selected: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;

    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    this.permissionToDelete(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  permissionToDelete = (id) => {
    const { selectedPermissions } = this.state;
    let selectedPermission = [...selectedPermissions];
    const isPresent = selectedPermissions.includes(id);
    const index = selectedPermissions.indexOf(id);

    if (!isPresent) {
      selectedPermission.push(id);

      this.setState({ selectedPermissions: selectedPermission });
      this.props.onPermissionSelect(selectedPermission);
    } else {
      selectedPermission.splice(index, 1);
      this.setState({ selectedPermissions: selectedPermission });
      this.props.onPermissionSelect(selectedPermission);
    }
  };
  render() {
    const { permissionList = [] } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, isDone, loading } =
      this.state;

    return (
      <>
        <div className="desktop-table">
          <section>
            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: "separate",
                }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={permissionList.length}
                  headCells={headCells}
                />
                {!loading &&
                isDone &&
                permissionList &&
                permissionList.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(permissionList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = this.isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={(event) =>
                                  this.handleClick(event, row.id)
                                }
                              />
                            </TableCell>
                            <TableCell className="p-0" align="left">
                              {row.resource_type !== ""
                                ? row.resource_type === "TENANT"
                                  ? "ACCOUNT"
                                  : row.resource_type
                                : "-"}
                            </TableCell>
                            <TableCell align="left" className="p-0">
                              <span
                                data-testid={`permission-check ${index}`}
                                className=""
                              >
                                {row.name}
                              </span>
                            </TableCell>
                            <TableCell className="p-0" align="left">
                              {row.operation}
                            </TableCell>

                            <TableCell className="p-0" align="left">
                              <span
                                className={`mb-0 text-capitalize ${
                                  row.status === "active" ||
                                  row.status === "Active"
                                    ? "available"
                                    : "pending"
                                }`}
                              >
                                {row.status}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>

            {loading ? (
              <SkeletonLoader height={30} time={1} />
            ) : isDone && permissionList && permissionList.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={permissionList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            ) : !loading && permissionList && permissionList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                Permissions not added to this role
              </p>
            ) : null}
          </section>
        </div>
        <div className="mobile-table">
          <section>
            {loading ? (
              <SkeletonLoader height={30} time={1} />
            ) : isDone && permissionList ? (
              permissionList.map((data, i) => {
                return (
                  <div key={i} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">Resource Type</div>
                      <div className="values">
                        {data.resource_type !== ""
                          ? data.resource_type === "TENANT"
                            ? "ACCOUNT"
                            : data.resource_type
                          : "-"}
                      </div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Resource Name</div>
                      <div className="values">{data.name}</div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Operation</div>
                      <div className="values">{data.operation}</div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Status</div>
                      <div
                        className={
                          data.status === "active" || data.status === "Active"
                            ? "available"
                            : "pending"
                        }
                      >
                        {data.status}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading && permissionList && permissionList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No data found
              </p>
            ) : null}
          </section>
        </div>
      </>
    );
  }
}

const headCells = [
  {
    id: "resource_type",
    numeric: false,
    disablePadding: false,
    label: "Resource Type",
  },
  {
    id: "resource_name",
    numeric: false,
    disablePadding: false,
    label: "Resource Name",
  },
  {
    id: "operation",
    numeric: false,
    disablePadding: false,
    label: "Operation",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];
