import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { EnhancedTableHead } from "../../../components/TableComponent/TableWithSelection";
import {
  getComparator,
  stableSort,
} from "../../../components/TableComponent/TableComponent";
import Layout from "../../../components/SideBar/Layout";
import CommonCard from "../../../components/CommonCard/CommonCard";
import {
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from "../../../Router/PathConst";
import RoleMenu from "./RoleMenu";
import ApiConstant from "../../../utils/apiConstant";
import { get, put } from "../../../utils/apiMethods";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";
import Delete from "../../../Modals/Common/Delete";
import {
  getRolesPermissionId,
  deleteMessage,
  getCurrentTenant,
} from "../../../utils/common";
import { CommonContext } from "../../../Context/context";

const crumbs = [
  {
    title: "Roles",
    path: ROLES_PATH,
    active: true,
  },
];

const options = [
  { value: "Inactive", label: "Inactive" },
  { value: "Active", label: "Active" },
];

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default class Roles extends React.Component {
  static contextType = CommonContext;

  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "",
      selected: [],
      page: 0,
      rowsPerPage: 10,
      UUID: [],
      roleList: [],
      roleRefList: [],
      loading: true,
      isDone: true,
      selectedRoles: [],
      isAllresource: false,
      remaining: 0,
    };
  }

  componentDidMount() {
    if (this.context && this.context.Objects && this.context.SetFunctions) {
      const { currentUser } = this.context.Objects;
      const { setSelectedPermissions } = this.context.SetFunctions;

      setSelectedPermissions([]);
      const { props } = currentUser;
      if (props && "sorting" in props) {
        const sort = JSON.parse(props.sorting);
        if ("Roles" in sort && sort.Roles.length > 0) {
          this.setState({ orderBy: sort.Roles[0], order: sort.Roles[1] });
        } else {
          this.setState({ orderBy: "" });
        }
      } else {
        this.setState({ orderBy: "" });
      }
    }

    this.getRoles();
  }

  // GET API CALL
  getRoles = async () => {
    const response = await get({ url: ApiConstant.GET_ROLES });

    let resData = response?.filter((val) => {
      if (val.status === "active") {
        val["status"] = "Active";
      } else {
        val["status"] = "Inactive";
      }
      return !val.name.startsWith("SHADOW");
    });

    const newArray = resData?.sort((a, b) => {
      if (a.is_system_role && !b.is_system_role) {
        return -1;
      }
      if (!a.is_system_role && b.is_system_role) {
        return 1;
      }
      return 0;
    });

    this.setState({
      roleList: newArray ?? [],
      roleRefList: newArray ?? [],
      isDone: true,
      loading: false,
    });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
    this.onSaveSort(property, isAsc ? "desc" : "asc");
  };

  onSaveSort = (value, order) => {
    const { currentUser } = this.context.Objects;
    const { props } = currentUser;
    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.Roles = [value, order];
      } else {
        obj.Roles = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.updateUser(array);
    } else {
      const obj = {
        Roles: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.updateUser(currentUser.props);
    }
  };

  updateUser = async (properties) => {
    const { currentUser } = this.context.Objects;
    const { setCurrentUser } = this.context.SetFunctions;
    const { username, email, id } = currentUser;

    const reqObj = {
      id: id,
      username: username,
      email: email,
      tenantId: getCurrentTenant(),
      props: properties,
    };

    const URL = `${ApiConstant.UPDATE_USER}/${id}`;

    const response = await put({
      url: URL,
      reqObj: reqObj,
    });

    if (response && response.id) {
      if ("sorting" in response.props) {
        setCurrentUser(response);
      }
    }
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.roleList.map((n) => n.id);

      this.setState({ selected: newSelecteds, selectedRoles: newSelecteds });

      return;
    }
    this.setState({ selected: [], selectedRoles: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    this.roleToDelete(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  // filter
  onFilter = (status) => {
    const { roleRefList } = this.state;

    const filterList = roleRefList.filter(
      (data) => data.status.toLowerCase() === status.toLowerCase()
    );
    // console.log("FILTERList", filterList);
    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  // search
  onSearchData = (value) => {
    const { roleRefList } = this.state;
    if (value !== "" && value !== undefined) {
      const result = roleRefList.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ roleList: result, page: 0 });
    } else {
      this.setState({ roleList: roleRefList });
    }
  };

  onFilterData = (value) => {
    // console.log("FILTER", value);
    let resArray = [];
    const { roleRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ roleList: resArray, page: 0 });

      // console.log("FILTER Response", resArray);
    } else {
      this.setState({ roleList: roleRefList });
    }

    // const filter = clusterList.filter((data)=>data.status  )
  };
  // DElete

  roleToDelete = (id) => {
    const { selectedRoles } = this.state;
    let selectedRole = [...selectedRoles];
    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  onDeleteRole = async () => {
    const { selectedRoles } = this.state;
    let success = [];
    let failure = [];

    for (let z = 0; z < selectedRoles.length; z++) {
      const response = await getRolesPermissionId(selectedRoles[z]);

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
    }

    if (success.length > 0) {
      deleteMessage(200, `${success.length} Roles deleted`);
    }
    if (failure.length > 0) {
      deleteMessage(200, `${failure.length} Roles failed to delete`, true);
    }
    this.onDeleteDone();
  };

  onDeleteDone = () => {
    this.setState(
      {
        roleList: [],
        selectedRoles: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: true,
      },
      () => {
        this.getRoles();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      roleList,
      loading,
      isDone,
      selectedRoles,
      isDelete,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {isDelete && (
          <Delete
            title={`${selectedRoles.length} Roles`}
            show={isDelete}
            onDelete={this.onDeleteRole}
            handleClose={this.handleDeleteModal}
          />
        )}

        <div className="d-flex justify-content-end gap-3">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(`${CREATE_ROLES_PATH}/create`)
            }
          >
            <i className="bi bi-plus-lg me-3" />
            Create Role
          </button>
          <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedRoles.length === 0}
          >
            <i className="bi bi-trash3 me-3" />
            Delete Roles
          </button>
        </div>
        <CommonCard className="mt-3">
          <div className="d-flex flex-column flex-md-row justify-content-between gap-3">
            <div className="col-md-3">
              <input
                className="input-boxes h-3 w-100"
                type="search"
                placeholder="Search By Name"
                onChange={(e) => this.onSearchData(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <MultiSelect
                placeholder="Filter By Status"
                isMulti
                closeMenuOnSelect={false}
                options={options}
                handleChange={(value) => this.onFilterData(value)}
              />
            </div>
          </div>

          <>
            <div className="desktop-table mt-4">
              <TableContainer>
                <Table
                  className="tableX"
                  sx={{
                    minWidth: 750,
                  }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={roleList.length}
                    headCells={headCells}
                  />
                  {isDone && roleList && roleList.length > 0 ? (
                    <TableBody className="table Table">
                      {stableSort(roleList, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = this.isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {!row.is_system_role && (
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                    onClick={(event) =>
                                      this.handleClick(event, row.id)
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                onClick={() =>
                                  !row.is_system_role
                                    ? this.props.history.push(
                                        `${ROLE_DETAILS_PATH}/${row.id}`,
                                        row
                                      )
                                    : null
                                }
                                align="left"
                              >
                                <span
                                  data-testid={`role-id ${index}`}
                                  className={!row.is_system_role ? "id" : ""}
                                >
                                  {row.name}
                                </span>
                              </TableCell>

                              <TableCell align="left">
                                {row.description ? row.description : "-"}
                              </TableCell>
                              <TableCell align="left">
                                <span
                                  style={{ paddingBottom: "0px" }}
                                  className={
                                    row.status === "inactive" ||
                                    row.status === "Inactive"
                                      ? "disable"
                                      : "available"
                                  }
                                >
                                  {row.status}
                                </span>
                              </TableCell>
                              <TableCell align="left">
                                {!row.is_system_role && (
                                  <RoleMenu
                                    onComplete={this.onDeleteDone}
                                    title={row.name}
                                    id={row.id}
                                    history={this.props.history}
                                    data={row}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
              {loading ? (
                <SkeletonLoader height={30} time={1} />
              ) : isDone && roleList && roleList.length > 0 ? (
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  component="div"
                  count={roleList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              ) : !loading && roleList && roleList.length === 0 ? (
                <p className="text_color text-center no-data-msg mt-2">
                  No data found
                </p>
              ) : null}
            </div>
            <div className="mobile-table mt-4">
              {loading ? (
                <SkeletonLoader height={30} time={1} />
              ) : isDone && roleList ? (
                roleList.map((data, i) => {
                  return (
                    <div key={i} className="mob-main">
                      <div className="mob-rows">
                        <div className="keys">Name</div>
                        <div
                          onClick={() =>
                            !data.is_system_role
                              ? this.props.history.push(
                                  `${ROLE_DETAILS_PATH}/${data.id}`,
                                  data
                                )
                              : null
                          }
                          className={
                            !data.is_system_role ? "values id" : "values"
                          }
                        >
                          {data.name}
                        </div>
                      </div>

                      <div className="mob-rows">
                        <div className="keys">Description</div>
                        <div className="values">
                          {data.description ? data.description : "-"}
                        </div>
                      </div>
                      <div className="mob-rows">
                        <div className="keys">Status</div>
                        <div
                          className={
                            data.status === "Inactive"
                              ? "disable values"
                              : "available values"
                          }
                        >
                          {data.status}
                        </div>
                      </div>
                      <div className="mob-rows">
                        <div className="keys">Actions</div>
                        <div className="text_color_main">
                          {!data.is_system_role && (
                            <RoleMenu
                              onComplete={this.onDeleteDone}
                              title={data.name}
                              id={data.id}
                              history={this.props.history}
                              data={data}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : !loading && roleList && roleList.length === 0 ? (
                <p className="text_color text-center no-data-msg mt-2">
                  No data found
                </p>
              ) : null}
            </div>
          </>
        </CommonCard>
      </Layout>
    );
  }
}
