import React from "react";
import "./Input.scss";

const Input = (props) => {
  const { name, type, placeholder, handleChange, label, error, maxLength } =
    props;

  return (
    <div className="form-group">
      <label className="inputLabel" htmlFor="formGroupInput">
        {label}
      </label>
      <input
        maxLength={maxLength}
        name={name}
        type={type}
        className="form-control formInput"
        id="formGroupInput"
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
      />
      {error ? <span className="error">{error}</span> : ""}
    </div>
  );
};

export default Input;
