import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DASHBOARD_PATH } from "../../Router/PathConst";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "loading",
    };
  }

  componentDidMount() {
    this.props.history.push(DASHBOARD_PATH);
  }

  render() {
    return <div>{this.state.display}</div>;
  }
}

export default withRouter(Home);
