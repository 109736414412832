export const DASHBOARD_PATH ='/dashboard' 
export const CLUSTER_PATH ='/cluster'
export const CREATE_CLUSTER_PATH ='/cluster/create'
export const CLUSTER_DETAILS_PATH ='/cluster/details'
export const JOBS_PATH ='/jobs'
export const JOBS_DETAIL_PATH ='/jobs/details'


export const TENANT_PATH ='/tenant'
export const TENANT_DETAILS_PATH ='/tenant/details'

export const CREATE_TENANT_PATH ='/tenant'
export const ALL_RESOURCES_PATH ='/allresources'
export const ROLES_PATH ='/roles'
export const CREATE_PERMISSION_PATH ='/permission'

export const CREATE_ROLES_PATH ='/roles'
export const ROLE_DETAILS_PATH ='/role/details'

export const USER_PATH ='/users'
export const CREATE_USER_PATH ='/users'
export const USER_DETAILS_PATH ='/users/details'
export const DATA_SOURCES_PATH ='/datasources'
export const CREATE_DATA_SOURCES_PATH ='/datasources/create'
export const DATA_SOURCES_DETAILS_PATH='/datasources/details'

export const ACCOUNT_PATH ='/accounts'
export const CREATE_ACCOUNT_PATH ='/accounts'
export const ACCOUNT_DETAILS_PATH ='/account/details'