import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { EnhancedTableHead } from "../../../../components/TableComponent/TableComponent";
import {
  getComparator,
  stableSort,
} from "../../../../components/TableComponent/TableComponent";
import SkeletonLoader from "../../../../components/Loader/SkeletonLoader";
import ViewMore from "../../../../Modals/Common/ViewMore";

export default class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "",
      selected: [],
      page: 0,
      rowsPerPage: 5,
      data: [],
      loading: false,
      isDone: true,
    };
  }

  componentDidMount() {
    const { properties } = this.props;
    if (properties !== undefined) {
      let array = [];
      let propArray = Object.keys(properties);

      for (let i = 0; i < propArray.length; i++) {
        if (
          !propArray[i].startsWith("bookmarks") &&
          !propArray[i].startsWith("sorting") &&
          !propArray[i].startsWith("ENABLE_API_ACCESS") &&
          !propArray[i].startsWith("ENCRYPTED_AES_KEY") &&
          !propArray[i].startsWith("API_Key") &&
          !propArray[i].startsWith("ACCESS_TOKEN")
        ) {
          const obj = {
            id: propArray[i],
            name: properties[propArray[i]],
          };
          array.push(obj);
        }
      }
      this.setState({ data: array });
    }
  }

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  opendViewMore = (data) => {
    this.setState({ viewMoreData: data, viewMore: true });
  };

  closedViewMore = () => {
    this.setState({ viewMoreData: "", viewMore: false });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      data,
      isDone,
      loading,
      viewMore,
      viewMoreData,
    } = this.state;

    return (
      <div>
        <div className="text-right">
          {viewMore && (
            <ViewMore
              show={viewMore}
              handleClose={this.closedViewMore}
              data={viewMoreData}
              fetchKey="name"
              headKey="id"
            />
          )}

          {/* <button
                className="buttonX">
                <i className="bi bi-trash3 me-2"/>Delete Properties
              </button> */}
        </div>
        <div className="desktop-table mt-2">
          <TableContainer>
            <Table
              className="tableX"
              sx={{
                minWidth: 750,
                borderCollapse: "separate",
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                headCells={headCells}
              />
              {isDone && data && data.length > 0 ? (
                <TableBody className="table Table">
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <>
                          <TableRow tabIndex={-1} key={row.name + index}>
                            <TableCell width="40%">
                              <span data-testid={`user-prop-id ${index}`}>
                                {row.id}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                overflowWrap: "anywhere",
                                padding: ".5rem",
                              }}
                              align="left"
                            >
                              {row.name.length > 50 ? (
                                <span className="p-0">
                                  {row.name.slice(0, 50)}{" "}
                                  <span
                                    onClick={() => this.opendViewMore(row)}
                                    className="view-more"
                                    data-testid={`viewMore ${index}`}
                                  >
                                    ...View More
                                  </span>
                                </span>
                              ) : (
                                row.name
                              )}
                            </TableCell>

                            {/* <TableCell align="center">
                            <BsThreeDotsVertical className="sideBar-icons" />
                          </TableCell> */}
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          {loading ? (
            <SkeletonLoader height={30} time={1} />
          ) : isDone && data && data.length > 0 ? (
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          ) : !loading && data && data.length === 0 ? (
            <p className="text_color text-center no-data-msg mt-2">
              No data Found
            </p>
          ) : null}
        </div>

        <div className="mobile-table">
          {data &&
            data.map((data, index) => {
              return (
                <div className="mob-main">
                  <div className="mob-rows">
                    <div className="keys">Name</div>
                    <div className="values">{data.id}</div>
                  </div>
                  <div className="mob-rows">
                    <div className="keys">Value</div>
                    <div className="values">
                      {data.name.length > 10 ? (
                        <span className="p-0">
                          {data.name.slice(0, 10)}{" "}
                          <span
                            onClick={() => this.opendViewMore(data)}
                            className="view-more"
                            // data-testid={`viewMore ${index}`}
                          >
                            ...View More
                          </span>
                        </span>
                      ) : (
                        data.name
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Value",
  },
];
