import React from "react";
import { Breadcrumbs } from "@mui/material";
import "./Crumbs.scss";
export default function Crums({ items, history }) {
  return (
    <div className="Crumbs">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {items &&
          items.map((bread, i) => {
            return (
              <p
                data-testid={`crumb-click ${i}`}
                key={i}
                className={bread.active ? "active" : "inActive"}
                onClick={() => (bread.active ? null : history.push(bread.path))}
              >
                {bread.title}
              </p>
            );
          })}
      </Breadcrumbs>
    </div>
  );
}
