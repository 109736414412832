import React, { Component } from "react";
import { FaCopy } from "react-icons/fa";
import ActivityLoader from "../../../components/Loader/ActivityLoader";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";
import Layout from "../../../components/SideBar/Layout";
import Delete from "../../../Modals/Common/Delete";
import PermissionList from "./PermissionList";
import {
  ALL_RESOURCES_PATH,
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from "../../../Router/PathConst";
import ApiConstant, { GET_ROLES_UUID } from "../../../utils/apiConstant";
import { deleteAPI, get, put } from "../../../utils/apiMethods";
import {
  createMessage,
  deleteMessage,
  exportData,
  getReadableDate,
  getRolesPermissionId,
} from "../../../utils/common";
import AddNewPermissionModal from "../../../Modals/Roles/AddNewPermissionModal";
import CommonCard from "../../../components/CommonCard/CommonCard";

export default class RoleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isData: false,
      permissions: [],
      permissionUUID: [],
      isDeletePermission: false,
      selectedPermissions: [],
      isAllResource: false,
      loading: true,
      actionDone: true,
      isAddNewPermissionModal: false,
      searchString: "",
    };
  }

  componentDidMount() {
    this.getRoleDetails();
    this.getPermissions();
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isAllResource
    ) {
      this.setState({ isAllResource: true });
    } else {
      this.setState({ isAllResource: false });
    }
  }

  getRoleDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_ROLE}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      response["status"] = response.status === "active" ? "Active" : "Inactive";

      this.setState({ data: response, loading: false, isData: true });
    } else {
      this.setState({ loading: false });
    }
  };

  // Get Permissions
  getPermissions = async () => {
    const roleId = this.props.match.params.id;

    const URL = `${ApiConstant.GET_ROLE_PERMISSIONS}/${roleId}/permissions?offset=0&limit=999`;

    const response = await get({ url: URL });

    this.setState({
      permissions: response,
      loading: false,
      actionDone: true,
    });
  };

  handleSelect = (i, data) => {
    const { permissions } = this.state;
    this.permissionToUnassign(data.id);
    const array = [...permissions];
    array[i].selected = !array[i].selected;
    this.setState({ permissions: array });
  };

  permissionToUnassign = (id) => {
    const { selectedPermissions } = this.state;
    let selectedPermission = [...selectedPermissions];
    const isPresent = selectedPermissions.includes(id);
    const index = selectedPermissions.indexOf(id);

    if (!isPresent) {
      selectedPermission.push(id);
      this.setState({ selectedPermissions: selectedPermission });
    } else {
      selectedPermission.splice(index, 1);
      this.setState({ selectedPermissions: selectedPermission });
    }
  };

  onUnassign = async (type) => {
    const { selectedPermissions } = this.state;

    for (let i = 0; i < selectedPermissions.length; i++) {
      if (type === "delete") {
        await this.onDeletePermission(selectedPermissions[i]);
      } else {
        await this.onUnassignPermission(selectedPermissions[i], false);
      }
    }

    this.setState(
      {
        permissions: [],
        permissionUUID: [],
        selectedPermissions: [],
        isDeletePermission: false,
      },
      () => {
        this.getPermissions();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  onDeleteRole = async () => {
    const { data } = this.state;

    const response = await getRolesPermissionId(data.id);

    deleteMessage(response, "Role deleted");

    setTimeout(() => {
      this.props.history.push(ROLES_PATH);
    }, 1000);
  };

  //unassign permission /permissions/{id}
  onUnassignPermission = async (permission, flag) => {
    const id = this.props.match.params.id;

    const response = await deleteAPI({
      url: `${ApiConstant.ASSIGN_ROLE_PERMISSION}/${id}/permissions/${permission}`,
      type: "Permission",
    });

    deleteMessage(response, "Permission unassigned");

    if (flag === undefined) {
      this.setState(
        { permissions: [], permissionUUID: [], selectedPermissions: [] },
        () => {
          this.getPermissions();
        }
      );
    }
  };

  handlePermissionDeleteModal = (data) => {
    this.setState({
      isDeletePermission: !this.state.isDeletePermission,
      permission: data,
    });
  };

  onDeletePermission = async (permissionId) => {
    const { data } = this.state;

    const response = await deleteAPI({
      url: `${ApiConstant.DELETE_ROLE_PERMISSION}/${data.id}/permissions/${permissionId}`,
      type: "Permission",
    });

    deleteMessage(response, "Permission Deleted");

    return response;
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onPermissionSelect = (list) => {
    this.setState({ selectedPermissions: list });
  };

  toggleAddNewPermissionModal = () => {
    this.setState({
      isAddNewPermissionModal: !this.state.isAddNewPermissionModal,
    });
  };

  addNewPermissionsInList = (permissionList) => {
    this.setState({
      permissions: [...permissionList, ...this.state.permissions],
    });
  };

  onSearchData = (search) => {
    this.setState({ searchString: search });
  };

  render() {
    const {
      data,
      permissions,
      isData,
      selectedPermissions,
      isDelete,
      isDeletePermission,
      isCopy,
      isAllResource,
      loading,
      actionDone,
      isAddNewPermissionModal,
      searchString,
    } = this.state;

    const crumbs = [
      {
        title: isAllResource ? "All Resources" : "Roles",
        path: isAllResource ? ALL_RESOURCES_PATH : ROLES_PATH,
        active: false,
      },
      {
        title: "Role Details",
        path: ROLE_DETAILS_PATH,
        active: true,
      },
    ];

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader height={30} time={1} />
          </div>
        ) : !loading && isData ? (
          <>
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 className="details-data-value" data-testid="name">
                      {data.name ? data.name : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">ID:</h6>
                    <h6
                      className="details-data-value copy-id"
                      data-testid="name"
                    >
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : "-"}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {" "}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Description:</h6>
                    <h6 className="details-data-value">
                      {data ? data.description : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={`text-capitalize details-data-value 
                          ${
                            data.status === "inactive" ||
                            data.status === "Inactive"
                              ? "disable"
                              : "available"
                          }
                        `}
                    >
                      {data && data.status}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  {!data.is_system_role && (
                    <button
                      className="buttonX details-button"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${CREATE_ROLES_PATH}/${data.id}`,
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      <i className="bi bi-pencil-square me-2" />
                      Edit Role
                    </button>
                  )}

                  <button
                    className="buttonX details-button"
                    onClick={() => exportData(data, `${data.name}-Role`)}
                  >
                    <i className="bi bi-cloud-arrow-up me-2" />
                    Export Json
                  </button>

                  {!data.is_system_role && (
                    <button
                      className="buttonX white details-button"
                      onClick={this.handleDeleteModal}
                    >
                      <i className="bi bi-trash3 me-2" />
                      Delete Role
                    </button>
                  )}
                </div>
              </div>
            </div>

            <CommonCard nCard className="mt-4">
              <div className="mb-4 d-flex flex-column flex-md-row  justify-content-between gap-4">
                <div className="">
                  <h4 className="text_color_main text-bold">Permissions</h4>
                  <p className="mb-0">Assigned to this role</p>
                </div>
                <div
                  className="d-flex flex-column flex-lg-row justify-content-end align-items-end gap-3"
                  style={{ flex: 1 }}
                >
                  <input
                    className="input-boxes col-12 col-md-7 col-lg-4 h-3"
                    type="search"
                    placeholder="Search By Name"
                    onChange={(e) => this.onSearchData(e.target.value)}
                  />

                  {actionDone ? (
                    <>
                      <button
                        className="buttonX"
                        type="button"
                        onClick={() => this.toggleAddNewPermissionModal()}
                      >
                        <i className="bi bi-pencil-square me-2" />
                        Add New Permission
                      </button>

                      <button
                        className="buttonX white"
                        onClick={this.handlePermissionDeleteModal}
                        disabled={selectedPermissions.length === 0}
                      >
                        <i className="bi bi-trash3 me-2" />
                        Delete Permission
                      </button>
                    </>
                  ) : (
                    <ActivityLoader />
                  )}
                </div>
              </div>
              {permissions && permissions.length > 0 ? (
                <PermissionList
                  permissionList={permissions.filter((permission) => {
                    if (searchString === "") {
                      return true;
                    }
                    return permission.name
                      .toLowerCase()
                      .includes(searchString.toLowerCase());
                  })}
                  onPermissionSelect={this.onPermissionSelect}
                  history={this.props.history}
                  onDeletePermission={this.onDeletePermission}
                />
              ) : (
                <p className="text_color_main text-center no-data-msg mt-2">
                  No permissions found
                </p>
              )}
            </CommonCard>
          </>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No data found
          </h4>
        )}

        {isDelete && (
          <Delete
            title={`${data.name} Role`}
            show={isDelete}
            onDelete={this.onDeleteRole}
            handleClose={this.handleDeleteModal}
          />
        )}

        {isDeletePermission && (
          <Delete
            title={`${selectedPermissions.length} Permission`}
            show={isDeletePermission}
            onDelete={() => this.onUnassign("delete")}
            handleClose={this.handlePermissionDeleteModal}
          />
        )}

        <AddNewPermissionModal
          show={isAddNewPermissionModal}
          handleClose={this.toggleAddNewPermissionModal}
          roleId={this.props?.match?.params?.id}
          addNewPermissionsInList={this.addNewPermissionsInList}
          currentPermissions={permissions}
        />
      </Layout>
    );
  }
}
