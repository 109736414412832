import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { put } from "../../utils/apiMethods";
import ApiConstant from "../../utils/apiConstant";
import { createMessage } from "../../utils/common";

export default function ActiveInactiveUserModal(props) {
  const [isAPILoading, setIsAPILoading] = useState(false);

  const onStatusChange = async () => {
    setIsAPILoading(true);

    const reqObj = {
      userStatus: props?.userData?.userStatus === -1 ? 0 : -1,
    };

    const URL = `${ApiConstant.UPDATE_USER}/${props?.userData?.id}`;

    const response = await put({
      url: URL,
      reqObj: reqObj,
    });

    if (response && response.id) {
      props.handleClose();
      props.updateUserStatus();

      const msg =
        props?.userData?.userStatus === -1
          ? "User activated "
          : "User deactivated ";

      createMessage(200, msg);
    } else {
      createMessage(response);
    }

    setIsAPILoading(false);
  };

  return (
    <Modal
      dialogClassName=""
      centered
      show={props.show}
      onHide={props.handleClose}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props?.userData?.userStatus === -1 ? "Activate" : "Disable"} User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to{" "}
        {props?.userData?.userStatus === -1 ? "activate" : "disable"} "
        {props?.userData?.username}" user?
      </Modal.Body>
      <Modal.Footer>
        <button className="buttonX" onClick={!isAPILoading && onStatusChange}>
          {isAPILoading
            ? "Loading..."
            : props?.userData?.userStatus === -1
            ? "Activate User"
            : "Disable User"}
        </button>
        <button className="buttonX white" onClick={props.handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
