import React, { Fragment } from "react";
import Select, { components } from "react-select";

const { ValueContainer, Placeholder } = components;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--clr-card-200)",
    color: "var(--clr-text-300)",
    textAlign: "left",
    ":hover": {
      color: "var(--clr-text-300)",
    },
  }),

  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--clr-card-100)",
    color: "var(--clr-text-300)",
    borderRadius: "4px",
    border: "1px solid var(--clr-input-border)",
    ":hover": {
      border: "1px solid var(--clr-input-border)",
    },
    ":focus": {
      border: "1px solid var(--clr-input-border-focus)",
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--clr-card-100)",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue ? -10 : 10,
    transition: "top 0.1s, font-size 0.1s",
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    background: state.hasValue && "var(--clr-input-background)",
    color: "var(--clr-input-text)",
    paddingLeft: "5px",
    paddingRight: "5px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    minHeight: "3rem",
    backgroundColor: "var(--clr-input-background)",
  }),

  multiValue: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "var(--clr-card-200)",
    };
  },

  multiValueLabel: (styles, state) => ({
    ...styles,
    color: "var(--clr-input-text)",
  }),

  multiValueRemove: (styles, state) => ({
    ...styles,
    color: "var(--clr-input-text)",
    ":hover": {
      color: "red",
      backgroundColor: "var(--clr-input-background)",
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    color: "var(--clr-input-text)",
    ":hover": {
      color: "var(--clr-text-300)",
    },
  }),

  clearIndicator: (base) => ({
    ...base,
    color: "var(--clr-input-text)",
    ":hover": {
      color: "var(--clr-input-text)",
    },
  }),
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child, index) =>
        child && child.type !== Placeholder ? (
          <Fragment key={"multi-select-" + index}>{child}</Fragment>
        ) : null
      )}
    </ValueContainer>
  );
};

export default function MultiSelect(props) {
  return (
    <div className="form-group mb-0 w-100">
      {props.label ? (
        <p className="text-left mb-1">
          {props.label}{" "}
          {props.isStar ? <span style={{ color: "red" }}>*</span> : null}
        </p>
      ) : null}

      <Select
        value={props.value}
        options={props.options}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        defaultValue={props.defaultValue}
        styles={customStyles}
        onChange={(e) => props.handleChange(e)}
        isSearchable={props.isSearchable}
        closeMenuOnSelect={props.closeMenuOnSelect}
        components={{
          ValueContainer: CustomValueContainer,
        }}
      />
      {props.error ? <span className="error">{props.error}</span> : ""}
    </div>
  );
}
