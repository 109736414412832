import ApiConstant, { GET_USER_ROLES } from "./apiConstant";

import { get, deleteAPI } from "./apiMethods";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";
import moment from "moment";

export const isLocalHost = () => {
  return window.location.hostname === "localhost";
};

export const getReadableDate = (date, onlyDate, onlyTime) => {
  var myDate = new Date(date * 1000);
  const finalDate = myDate.toLocaleString();
  if (onlyDate) {
    return finalDate.slice(0, 10);
  } else if (onlyTime) {
    return finalDate.slice(11, 17);
  } else {
    return moment(date * 1000).format("MM/DD/YYYY, hh:mm:ss A");
  }
};

export const getDataId = (data) => {
  let array = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      array.push(data[i].id);
    }
  }
  return array;
};

export const getValidTimeStamps = (date) => {
  const readable = getReadableDate(date, true);
  var localDate = new Date().toLocaleString().split(",");
  const today = localDate[0].split("/");
  const received = readable.split("/");
  if (today[0] === received[0]) {
    const hours = new Date(date * 1000).getHours();
    var ampm = hours >= 12 ? "pm" : "am";

    return hours + ampm;
  } else if (today[1] === received[1]) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sepr",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = new Date(date * 1000).getMonth();
    const currentDate = new Date(date * 1000).getDate();
    return currentDate + months[month];
  } else {
    return readable;
  }
};

export const getPropArray = (data) => {
  let array = [];

  if (data && data.length > 0) {
    data.forEach((element) => {
      const obj = {
        id: element.id.Value,
        name: element.name.Value,
      };
      array.push(obj);
    });
  }
  return array;
};

export const getPropsStructure = (items, config) => {
  let array = [];

  if (items && items.length > 0) {
    items.forEach((item) => {
      if (item.id !== "CLUSTER_CREATION_ERROR") {
        const index = config.findIndex((x) => x.id.Value === item.id);
        if (index !== -1 && config[index].id.Value === item.id) {
          config[index].name.Value = item.name;
          array.push(config[index]);
        } else {
          const obj = {
            id: {
              Value: item.id,
              Restrict: {
                displayprompt: "Name",
                editable: true,
                sensitive: false,
                default: "",
                required: false,
                valuehelp: "",
                valuevalidation: "",
              },
            },
            name: {
              Value: item.name,
              Restrict: {
                displayprompt: "Value",
                editable: true,
                sensitive: true,
                default: "",
                required: false,
                valuehelp: "",
                valuevalidation: "",
              },
            },
            Restrict: {
              displayprompt: "",
              editable: true,
              sensitive: false,
              default: '""',
              required: false,
              valuehelp: "",
              valuevalidation: "",
            },
          };
          array.push(obj);
        }
      }
    });
  }

  return array.sort((a, b) => {
    if (a.id?.Restrict?.required && !b.id?.Restrict?.required) return -1;
    if (!a.id?.Restrict?.required && b.id?.Restrict?.required) return 1;
    if (a.id?.Value === "Compute" && b.id?.Value !== "Compute") return 1;
    if (a.id?.Value !== "Compute" && b.id?.Value === "Compute") return -1;
    return 0;
  });
};

// Users
let count = 0;

export const deleteUserById = async (id) => {
  const response = await deleteAPI({
    url: `${ApiConstant.DELETE_USER}/${id}`,
    type: "User",
  });

  return response;
};

// Roles
let rolesPermission = [];

export const getRolesPermissionId = async (id) => {
  rolesPermission = await get({
    url: `${ApiConstant.GET_ROLE_PERMISSIONS}/${id}/permissions?offset=0&limit=999`,
  });

  if (rolesPermission && rolesPermission.length > 0) {
    for (let i = 0; i < rolesPermission.length; i++) {
      onUnassignPermission(rolesPermission[i].id, id);

      if (i === rolesPermission.length - 1) {
        const response = await deleteAPI({
          url: `${ApiConstant.DELETE_ROLE}/${id}`,
          type: "Role",
        });

        return response;
      }
    }
  } else {
    const response = await deleteAPI({
      url: `${ApiConstant.DELETE_ROLE}/${id}`,
      type: "Role",
    });
    return response;
  }
};

const onUnassignPermission = async (deleteId, id) => {
  await deleteAPI({
    url: `${ApiConstant.DELETE_ROLE_PERMISSION}/${id}/permissions/${deleteId}`,
    type: "Role",
  });

  count = count + 1;
  console.log("PERMISSION COUNT", count);
};

export const checkProps = (props) => {
  let finalArray = [];
  if (props && props.length > 0) {
    props.forEach((element) => {
      if (element.id !== "" || element.name) {
        finalArray.push(element);
      }
    });
  }
  return finalArray;
};

// save sorting
export const createMessage = (status, msg, custom) => {
  if (status === 200) {
    if (custom) {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
      });
    } else {
      toast.success(`${msg} successfully`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
      });
    }
  } else if (status === 400) {
    toast.error(`Invalid input , ${msg}`, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 401) {
    toast.error("Authentication needed before performing this operation", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 403) {
    toast.error("Insufficient permissions to perform this operation", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 404) {
    toast.error("The requested resource was not found", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 409) {
    toast.error("Object could not be created due to conflict", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else {
    toast.error("Something went wrong ", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  }
};

export const deleteMessage = (status, msg, custom) => {
  if (status === 200 || status === 201 || status === 204) {
    if (custom) {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
      });
    } else {
      toast.success(`${msg} successfully`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
      });
    }
  } else if (status === 400) {
    toast.error("Invalid input", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 401) {
    toast.error("Authentication needed before performing this operation", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 403) {
    toast.error("Insufficient permissions to perform this operation", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 409) {
    toast.error("Object could not be deleted due to conflict", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else if (status === 404) {
    toast.error("The requested resource was not found", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  } else {
    toast.error("Something went wrong ", {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
    });
  }
};

//  get status counts
export const getStatusCounts = (array, key, value) => {
  let result = 0;
  if (array && array.length > 0) {
    array.forEach((element) => {
      if (element[key] === value) {
        result++;
      }
    });
  }
  return result;
};

// decode cookie
export const getCurrentTenant = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookiee = cookies[i].trim();
    if (cookiee.startsWith("idToken")) {
      const keyPair = cookiee.split("=");
      if (keyPair && keyPair.length > 1) {
        const array = keyPair[1].split(".");
        const decoded = Base64.decode(array && array.length > 0 && array[1]);
        const final = JSON.parse(decoded);
        console.log("getCurrentTenant", final.tenant_id);
        return final.tenant_id;
      }
    }
  }
};

export const getCookieData = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookiee = cookies[i].trim();
    if (cookiee.startsWith("idToken")) {
      const keyPair = cookiee.split("=");
      if (keyPair && keyPair.length > 1) {
        const array = keyPair[1].split(".");
        const decoded = Base64.decode(array && array.length > 0 && array[1]);
        return JSON.parse(decoded);
      }
    }
  }
};

// export json
export const exportData = (data, fileName) => {
  let newData;

  if ("tenant_id" in data) {
    const { tenant_id, ...restOfObject } = data;
    newData = {
      account_id: tenant_id,
      ...restOfObject,
    };
  } else {
    newData = { ...data };
  }

  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(newData)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `${fileName}.json`;

  link.click();
};

export const deleteAccountById = async (id) => {
  const response = await deleteAPI({
    url: `${ApiConstant.DELETE_ACCOUNT}/${id}`,
    type: "v2",
  });

  return response;
};
