import React, { Component } from "react";
import Layout from "../../../components/SideBar/Layout";

import {
  ALL_RESOURCES_PATH,
  CREATE_USER_PATH,
  USER_DETAILS_PATH,
  USER_PATH,
} from "../../../Router/PathConst";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel";
import Roles from "./user_detail_tabs/Roles";
import Properties from "./user_detail_tabs/Properties";
import { get } from "../../../utils/apiMethods";
import ApiConstant from "../../../utils/apiConstant";
import {
  deleteMessage,
  exportData,
  getReadableDate,
  deleteUserById,
} from "../../../utils/common";
import Delete from "../../../Modals/Common/Delete";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";
import { FaCopy } from "react-icons/fa";
import AccessToken from "./user_detail_tabs/AccessToken";
import ActiveInactiveUserModal from "../../../Modals/Users/ActiveInactiveUserModal";
import CommonCard from "../../../components/CommonCard/CommonCard";

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleModal: false,
      selectedTab: 0,
      value: 0,
      data: {},
      isData: false,
      roleUUID: [],
      userRoleList: [],
      properties: {},
      isDelete: false,
      loading: true,
      isAllresource: false,
      isTenantCopy: false,
      isProfile: false,
      isActiveInactiveModalVisible: false,
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.updateBreadCrumb();
  }

  updateBreadCrumb = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isAllresource
    ) {
      this.setState({ isAllresource: true, isProfile: false });
    } else if (
      this.props.location.state !== undefined &&
      this.props.location.state.isProfile
    ) {
      this.setState({ isProfile: true, isAllresource: false });
    } else {
      this.setState({ isAllresource: false, isProfile: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getUserDetails();
      });
      this.updateBreadCrumb();
    }
  }

  getUserDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_USER}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (response.userStatus === undefined) {
        response.userStatus = 0;
      }
      this.setState({ data: response, isData: true, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // Delete
  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteUser = async () => {
    const id = this.props.match.params.id;

    const response = await deleteUserById(id);

    deleteMessage(response, "User deleted");

    setTimeout(() => {
      this.props.history.push(USER_PATH);
    }, 1000);
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onTenantCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isTenantCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isTenantCopy: false });
      }, 1000);
    });
  };

  toggleActiveInactiveModal = () => {
    this.setState({
      isActiveInactiveModalVisible: !this.state.isActiveInactiveModalVisible,
    });
  };

  updateUserStatus = () => {
    this.setState({
      data: {
        ...this.state?.data,
        userStatus: this.state?.data?.userStatus === -1 ? 0 : -1,
      },
    });
  };

  render() {
    const {
      isTenantCopy,
      isAllresource,
      isDelete,
      value,
      data,
      isData,
      isCopy,
      loading,
      isProfile,
      isActiveInactiveModalVisible,
    } = this.state;

    let crumbs = [];

    if (isProfile) {
      crumbs = [
        {
          title: "My profile",
          path: USER_DETAILS_PATH,
          active: true,
        },
      ];
    } else {
      crumbs = [
        {
          title: isAllresource ? "All Resources" : "Users",
          path: isAllresource ? ALL_RESOURCES_PATH : USER_PATH,
          active: false,
        },
        {
          title: "User Details",
          path: USER_DETAILS_PATH,
          active: true,
        },
      ];
    }

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div>
            <SkeletonLoader height={30} time={1} />
          </div>
        ) : !loading && isData ? (
          <div>
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 className="details-data-value" data-testid="user-name">
                      {data ? data.username : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">User ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : "-"}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          className="copy-icon ms-1"
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          onClick={() => this.onCopy(data.id)}
                        >
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Account ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onTenantCopy(data.id)}
                      >
                        {data.tenantId ? data.tenantId : "-"}
                      </span>

                      {isTenantCopy ? (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="tenantIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onTenantCopy(data.tenantId)}
                        >
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Email:</h6>
                    <h6 className="details-data-value">
                      {data ? data.email : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={`text-capitalize details-data-value 
                          ${data.userStatus !== 0 ? "disable" : "available"}
                        `}
                    >
                      {data.userStatus !== 0 ? "Inactive" : "Active"}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="details-section-2">
                <div className="details-button-group">
                  <button
                    className="buttonX details-button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${CREATE_USER_PATH}/${data.id}`,
                        state: {
                          id: data.id,
                        },
                      })
                    }
                  >
                    <i className="bi bi-pencil-square me-2" />
                    Edit User
                  </button>
                  <button
                    className="buttonX details-button"
                    onClick={() => exportData(data, `${data.username}-User`)}
                  >
                    <i className="bi bi-cloud-arrow-up me-2" />
                    Export Json
                  </button>
                  <button
                    className="buttonX white details-button"
                    onClick={this.handleDeleteModal}
                  >
                    <i className="bi bi-trash3 me-2" />
                    Delete User
                  </button>
                  {/*
                      <button
                    className="buttonX white details-button"
                        onClick={this.toggleActiveInactiveModal}
                      >
                        <i
                          className={`bi ${
                            data.userStatus !== 0
                              ? "bi-person-check"
                              : "bi-person-dash"
                          } me-2`}
                        />
                        {data.userStatus !== 0
                          ? "Activate User"
                          : "Disable User"}
                      </button>
                   */}
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <Tabs
                variant="fullWidth"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={value}
                onChange={this.handleChange}
                aria-label="User Roles and Properties tables"
              >
                <Tab
                  className={`tabX-tab-head ${0 === value ? "active" : ""}`}
                  style={{ flex: "1" }}
                  label="Roles"
                />
                {/* <Tab
                  className="tabX-tab-head"
                  style={{ flex: "1" }}
                  label="Access Tokens"
                /> */}
                <Tab
                  className={`tabX-tab-head ${1 === value ? "active" : ""}`}
                  style={{ flex: "1" }}
                  label="Properties"
                />
              </Tabs>

              <SwipeableViews
                axis={useTheme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={0}
                  dir={useTheme.direction}
                >
                  <Roles
                    id={data.id}
                    onAssign={this.onRoleAssign}
                    history={this.props.history}
                  />
                </TabPanel>
                {/* <TabPanel value={value} index={1} dir={useTheme.direction}>
                  <AccessToken id={data.id} />
                </TabPanel> */}
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={1}
                  dir={useTheme.direction}
                >
                  <Properties properties={data.props} />
                </TabPanel>
              </SwipeableViews>
            </CommonCard>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No data found
          </h4>
        )}

        <ActiveInactiveUserModal
          show={isActiveInactiveModalVisible}
          handleClose={this.toggleActiveInactiveModal}
          updateUserStatus={this.updateUserStatus}
          userData={data}
        />

        {isDelete && (
          <Delete
            title={data.username}
            show={isDelete}
            onDelete={this.deleteUser}
            handleClose={this.handleDeleteModal}
          />
        )}
      </Layout>
    );
  }
}
