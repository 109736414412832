export let V1_API = "/v1";

export const GET_ACCESS_TOKEN = `${V1_API}/users/`;
export const CREATE_ACCESS_TOKEN = `${V1_API}/users/`;
export const DELETE_ACCESS_TOKEN = `${V1_API}/users/`;
export const ACTIVATE_ACCESS_TOKEN = `${V1_API}/users/`;
export const DEACTIVATE_ACCESS_TOKEN = `${V1_API}/users/`;

export default class ApiConstant {
  /// Accounts
  static GET_ACCOUNTS = (offset, limit) =>
    `${V1_API}/accounts?offset=${offset}&limit=${limit}`;

  static GET_ACCOUNT = `${V1_API}/accounts`;
  static ADD_ACCOUNT = `${V1_API}/accounts`;
  static UPDATE_ACCOUNT = `${V1_API}/accounts`;
  static DELETE_ACCOUNT = `${V1_API}/accounts`;

  /// Clusters
  static GET_CLUSTERS = `${V1_API}/clusters?offset=0&limit=999`;

  static GET_CLUSTER = `${V1_API}/clusters`;
  static CREATE_CLUSTER = `${V1_API}/clusters`;
  static UPDATE_CLUSTER = `${V1_API}/clusters`;

  /// Users
  static GET_USERS = `${V1_API}/users?offset=0&limit=999`;

  static GET_USER = `${V1_API}/users`;
  static ADD_USER = `${V1_API}/users`;
  static UPDATE_USER = `${V1_API}/users`;
  static DELETE_USER = `${V1_API}/users`;

  static GET_USER_ROLES = `${V1_API}/users`;
  static ASSIGN_ROLE_TO_USER = `${V1_API}/users`;
  static DELETE_ROLE_FROM_USER = `${V1_API}/users`;

  /// Roles
  static GET_ROLES = `${V1_API}/roles?offset=0&limit=999`;

  static GET_ROLE = `${V1_API}/roles`;
  static ADD_ROLE = `${V1_API}/roles`;
  static UPDATE_ROLE = `${V1_API}/roles`;
  static DELETE_ROLE = `${V1_API}/roles`;

  /// Permissions
  static CREATE_ROLE_PERMISSION = `${V1_API}/roles`;
  static GET_ROLE_PERMISSIONS = `${V1_API}/roles`;
  static ASSIGN_ROLE_PERMISSION = `${V1_API}/roles`;
  static UPDATE_ROLE_PERMISSION = `${V1_API}/roles`;
  static DELETE_ROLE_PERMISSION = `${V1_API}/roles`;
}
