import { Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import Delete from "../../Modals/Common/Delete";
import {
  CREATE_ACCOUNT_PATH,
  ACCOUNT_DETAILS_PATH,
} from "../../Router/PathConst";
import { exportData, deleteMessage, deleteUserById } from "../../utils/common";
import ActiveInactiveAccountModal from "../../Modals/Accounts/ActiveInactiveAccountModal";

export default function AccountMenu({
  id,
  history,
  onComplete,
  title,
  status,
  data,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [isActiveInactiveModalVisible, setIsActiveInactiveModalVisible] =
    React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAccountId(id);
  }, [id]);

  const openDelete = () => {
    setAnchorEl(null);
    setIsDelete(true);
  };

  const closeDelete = () => setIsDelete(false);

  const onDeleteAccount = async () => {
    setIsDelete(false);
    const response = await deleteUserById(id);
    if (response === 200) {
      deleteMessage(200, ` account deleted`);
    } else {
      deleteMessage(response);
    }
    onComplete();
  };

  function toggleActiveInactiveModal() {
    handleClose();
    setIsActiveInactiveModalVisible((currentState) => !currentState);
  }

  function updateAccountStatus() {
    onComplete();
  }

  return (
    <>
      <i
        data-testid="user-dots"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
        className="bi bi-three-dots-vertical dots"
      ></i>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${ACCOUNT_DETAILS_PATH}/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          View Details
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${CREATE_ACCOUNT_PATH}/${accountId}`,
              state: {
                id: accountId,
              },
            })
          }
        >
          Edit Account
        </MenuItem>

        {/* <MenuItem onClick={() => exportData(data, `${data.username}-User`)}>
          Export Json
        </MenuItem> */}

        {/* <MenuItem onClick={openDelete}>Delete Account</MenuItem> */}

        <MenuItem onClick={toggleActiveInactiveModal}>
          {status === "deactivated" ? "Activate Account" : "Deactivate Account"}
        </MenuItem>
      </Menu>

      {/* {isDelete && (
        <Delete
          title={`${title} Account`}
          show={isDelete}
          onDelete={onDeleteAccount}
          handleClose={closeDelete}
        />
      )} */}

      <ActiveInactiveAccountModal
        show={isActiveInactiveModalVisible}
        handleClose={toggleActiveInactiveModal}
        accountData={data}
        updateAccountStatus={updateAccountStatus}
      />
    </>
  );
}
