import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function SkeletonLoader({ height, time }) {
  return (
    <div>
      <SkeletonTheme
        baseColor="var(--clr-card-200)"
        highlightColor="var(--clr-card-300)"
        height={height}
        duration={time}
      >
        <Skeleton />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="var(--clr-card-200)"
        highlightColor="var(--clr-card-300)"
        height={height}
        duration={time}
      >
        <Skeleton />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="var(--clr-card-200)"
        highlightColor="var(--clr-card-300)"
        height={height}
        duration={time}
      >
        <Skeleton />
      </SkeletonTheme>
    </div>
  );
}
