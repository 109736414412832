import React from "react";
import HeaderNew from "../Header/HeaderNew";
import Footer from "../Footer/Footer";

const Main = (props) => {
  //   const intl = useIntl();
  return (
    <main style={{ padding: "0px", height: "100vh" }}>
      <div className="mobile-sidebar">
        <HeaderNew
          history={props.history}
          crumbs={props.crumbs}
          handleToggle={() => props.handleToggleSidebar(true)}
        />
      </div>
      {/* <Header /> */}
      <div
        className={
          window.location.hash.includes("#/dashboard")
            ? "flow-y content"
            : "content"
        }
      >
        {props.children}
      </div>

      <Footer />
    </main>
  );
};

export default Main;
