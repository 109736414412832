import { TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CommonCard from "../../../components/CommonCard/CommonCard";
import Layout from "../../../components/SideBar/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { CREATE_USER_PATH, USER_PATH } from "../../../Router/PathConst";
import { get, post, put } from "../../../utils/apiMethods";
import ApiConstant from "../../../utils/apiConstant";
import { checkProps, createMessage } from "../../../utils/common";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";
import ActivityLoader from "../../../components/Loader/ActivityLoader";

export default function AddAndUpdateUser(props) {
  const [type, setType] = useState("add");
  const [data, setData] = useState({});
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);

  const [hiddenProps] = useState([
    "bookmarks",
    "sorting",
    "ENABLE_API_ACCESS",
    "ENCRYPTED_AES_KEY",
    "API_Key",
    "ACCESS_TOKEN",
  ]);

  const [propsArray, setPropsArray] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  const crumbs = [
    {
      title: "Users",
      path: USER_PATH,
      active: false,
    },
    {
      title: type === "edit" ? "Update User" : "Create User",
      path: CREATE_USER_PATH,
      active: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email Id is Required"),
    password: Yup.string()
      .min(8, "Password should more than 8 characters")
      .max(12, "Password should less than 12 characters"),
    userName: Yup.string().required("Please enter user name"),
  });

  const formik = useFormik({
    initialValues: {
      fName:
        type === "edit" ? data.firstName : isFile ? jsonData.firstName : "",
      lName: type === "edit" ? data.lastName : isFile ? jsonData.lastName : "",
      email: type === "edit" ? data.email : isFile ? jsonData.email : "",
      password: "",
      userName:
        type === "edit" ? data.username : isFile ? jsonData.username : "",
      phoneNumber: type === "edit" ? data.phone : isFile ? jsonData.phone : "",
      tenantId:
        type === "edit" ? data.tenantId : isFile ? jsonData.tenantId : "",
      created_timestamp: type === "edit" ? data.created_timestamp : "",
      userStatus:
        type === "edit" ? data.userStatus : isFile ? jsonData.userStatus : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.match.params.type === "create") {
      setType(props.match.params.type);
    } else {
      setType("edit");
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getUserData = async () => {
      const id = props.match.params.type;

      const URL = `${ApiConstant.GET_USER}/${id}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);

        if (response.props && response.props !== undefined) {
          objToArray(response.props);
        }
        setLoading(false);
      }
    };

    if (props.match.params.type !== "create") {
      getUserData();
    }
  }, [props.match.params.type]);

  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: "",
      name: "",
    };
    array.push(obj);
    setPropsArray(array);
  };

  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };

  const hadleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === "id") {
      array[index].id = e.target.value;
    } else {
      array[index].name = e.target.value;
    }
    setPropsArray(array);
  };

  // new
  const getUserData = async (ID) => {
    const id = props.match.params.type;

    const URL = `${ApiConstant.GET_USER}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      setData(response);
      setActionDone(true);
      if (response.props && response.props !== undefined) {
        objToArray(response.props);
      }
    }
  };

  const onSubmitCall = async (values) => {
    setActionDone(false);

    var newProps = [];

    for (const key in data.props) {
      if (hiddenProps.includes(key) && key !== "") {
        const obj = {
          id: key,
          name: data.props[key],
        };

        newProps.push(obj);
      }
    }

    if (type === "edit") {
      const id = props.match.params.type;

      const allProperties = arrayToObj([...propsArray, ...newProps]);

      const reqObj = {
        username: values.userName,
        email: values.email,
        props: allProperties,
      };

      const URL = `${ApiConstant.UPDATE_USER}/${id}`;

      const response = await put({
        url: URL,
        reqObj: reqObj,
        type: "v2",
      });

      const newResponse = await response.json();

      if (response.status === 200) {
        createMessage(200, "User updated");

        setTimeout(() => {
          getUserData();
        }, 1000);
      } else if (response.status === 400) {
        createMessage(400, newResponse.message);
      } else {
        createMessage(response.status);
      }
      setActionDone(true);
    } else {
      const reqObj = {
        username: values.userName,
        email: values.email,
        props: arrayToObj(checkProps(propsArray)),
      };

      const response = await post({
        url: ApiConstant.ADD_USER,
        obj: reqObj,
        type: "v2",
      });

      const newResponse = await response.json();

      if (response.status === 200) {
        createMessage(200, "User created");

        setTimeout(() => {
          props.history.push(USER_PATH);
        }, 1000);
      } else if (response.status === 400) {
        createMessage(400, newResponse.message);
      } else {
        createMessage(response.status);
      }
      setActionDone(true);
    }
  };

  const arrayToObj = (array) => {
    let obj = {};
    if (array && array.length > 0) {
      obj = array.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item.name,
        }),
        {}
      );
    }

    return obj;
  };

  const objToArray = (properties) => {
    if (properties !== undefined) {
      let array = [];
      let propArray = Object.keys(properties);

      for (let i = 0; i < propArray.length; i++) {
        const obj = {
          id: propArray[i],
          name: properties[propArray[i]],
        };
        if (!hiddenProps?.includes(propArray[i])) {
          array.push(obj);
        }
      }
      setPropsArray(array);
    }
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const response = JSON.parse(e.target.result);
      console.log("Import data", response);
      setJsonData(response);
      setIsFile(true);
      if (response.props && response.props !== undefined) {
        objToArray(response.props);
      }
    };
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="add-edit-section">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left">
              {type === "edit" ? "Update User" : "Create User"}
            </h4>
            {type === "edit" ? null : (
              <>
                <div className="text-left my-3">
                  <label className="buttonX" htmlFor="file-import">
                    <i className="bi bi-download"> </i>Import JSON
                  </label>
                  <input
                    id="file-import"
                    data-testid="file-import"
                    type="file"
                    accept="application/json"
                    onChange={onJsonUpload}
                  />
                </div>

                <p className="text-left text_primary mb-0">
                  Or Create User with following details
                </p>
              </>
            )}
          </div>

          {type === "edit" && loading ? (
            <SkeletonLoader height={30} time={1} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper">
                <div>
                  <TextField
                    autoComplete="off"
                    inputProps={{ "data-testid": "username-Input" }}
                    style={{ width: "100%" }}
                    variant="outlined"
                    id="userName"
                    name="userName"
                    label="User Name"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    autoComplete="off"
                    inputProps={{ "data-testid": "email-Input" }}
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="props-wrapper">
                  <p className="prop-label mt-2">
                    Properties
                    <span>
                      <i
                        className={
                          propsArray.length === 0
                            ? "bi bi-plus-circle add-icon ms-2 text-primary cursor-pointer h4"
                            : "bi bi-plus-circle vis-hidden"
                        }
                        onClick={
                          propsArray.length === 0 ? () => addProps() : null
                        }
                      />
                    </span>
                  </p>

                  <div className="props-box">
                    {propsArray &&
                      propsArray.map((item, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center mt-2"
                            key={i}
                          >
                            <div className="inputs">
                              <input
                                data-testid={`id-prop ${i}`}
                                className="form-control id-input"
                                placeholder="name"
                                onChange={(e) => hadleChangeProps(e, i, "id")}
                                value={item.id}
                              />
                            </div>
                            <div className="inputs">
                              <input
                                data-testid={`name-prop ${i}`}
                                className="form-control id-input"
                                placeholder="value"
                                value={item.name}
                                onChange={(e) => hadleChangeProps(e, i, "name")}
                              />
                            </div>
                            <div
                              className="d-flex justify-content-end"
                              style={{ width: "80px" }}
                            >
                              <i
                                data-testid={`delete-prop ${i}`}
                                onClick={() => deleteProps(i)}
                                className={"bi bi-dash-circle close-icon"}
                              />
                              <i
                                data-testid={`add-prop ${i}`}
                                onClick={
                                  i === propsArray.length
                                    ? null
                                    : () => addProps()
                                }
                                className={
                                  i === propsArray.length - 1
                                    ? "bi bi-plus-circle add-icon"
                                    : "bi bi-plus-circle vis-hidden"
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                {actionDone ? (
                  <>
                    <button className="buttonX" type="submit">
                      {type === "edit" ? "Update User" : "Create User"}
                    </button>
                    <button
                      className="buttonX white"
                      type="button"
                      onClick={() =>
                        type === "edit"
                          ? props.history.goBack()
                          : props.history.push(USER_PATH)
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </form>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
