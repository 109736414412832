import React, { Component } from "react";
import ApiConstant from "../../../../utils/apiConstant";
import { deleteAPI, get, put } from "../../../../utils/apiMethods";
import { ROLE_DETAILS_PATH } from "../../../../Router/PathConst";
import Delete from "../../../../Modals/Common/Delete";
import AssignRoleModal from "../../../../Modals/Users/AssignRoleModal";
import { createMessage, deleteMessage } from "../../../../utils/common";
import SkeletonLoader from "../../../../components/Loader/SkeletonLoader";
import ActivityLoader from "../../../../components/Loader/ActivityLoader";

export default class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleUUID: [],
      userRoleList: [],
      loading: true,
      selectedRoles: [],
      isDelete: false,
      deleteId: {},
      isSingleDelete: false,
      assignRoleModal: false,
      assignedRoles: [],
      actionDone: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getUserRoles();
    });
  }

  /// User roles
  getUserRoles = async () => {
    const { id } = this.props;

    try {
      const response = await get({
        url: `${ApiConstant.GET_USER_ROLES}/${id}/roles`,
      });

      const roleUUID = response || [];

      if (roleUUID.length > 0) {
        const userRoleList = await this.fetchRoleDetails(roleUUID);

        this.setState({
          userRoleList: userRoleList.filter(Boolean),
          loading: false,
          actionDone: true,
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
      this.setState({ loading: false });
    }
  };

  fetchRoleDetails = async (roleUUID) => {
    try {
      const rolePromises = roleUUID.map(async (id) => {
        const URL = `${ApiConstant.GET_ROLE}/${id}`;
        const response = await get({ url: URL });

        if (response && response.name && !response.name.startsWith("SHADOW")) {
          return response;
        }
      });

      return Promise.all(rolePromises);
    } catch (error) {
      console.error("Error fetching role details:", error);
      return [];
    }
  };

  rolesToDelete = (id) => {
    const { selectedRoles } = this.state;
    let selectedRole = [...selectedRoles];
    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  // DELETE
  onDeleteRole = async () => {
    const { selectedRoles } = this.state;
    const { id } = this.props;
    let success = [];
    let failure = [];

    for (let z = 0; z < selectedRoles.length; z++) {
      const response = await deleteAPI({
        url: `${ApiConstant.DELETE_ROLE_FROM_USER}/${id}/roles/${selectedRoles[z]}`,
        type: "Role",
      });

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
      // await deleteAPI({ url: `${ADD_ROLES}/${selectedRoles[z]}` });
      // await   getRolesPermissionId(selectedRoles[z])
    }

    if (success.length > 0) {
      deleteMessage(200, `${success.length} Roles unassigned`);
    } else if (failure.length > 0) {
      deleteMessage(200, `${failure.length} roles failed to unassigned`, true);
    }

    this.onDeleteDone();
  };

  onDeleteDone = () => {
    this.setState(
      {
        userRoleList: [],
        selectedRoles: [],
        selected: [],
        isDelete: false,
        loading: true,
      },
      () => {
        this.getUserRoles();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteRole = (value) => {
    this.setState({ deleteId: value, isSingleDelete: true });
  };

  onSingleDelete = async (data) => {
    const { id } = this.props;

    const response = await deleteAPI({
      url: `${ApiConstant.DELETE_ROLE_FROM_USER}/${id}/roles/${data.id}`,
      type: "Role",
    });

    deleteMessage(response, "Role unassigned");

    //  await getRolesPermissionId(deleteId.id)
    this.setState({ deleteId: {}, isSingleDelete: false }, () => {
      this.onDeleteDone();
    });
  };

  // assign
  onRoleAssign = async (data, uuid) => {
    await this.setState({ actionDone: false }, () => {
      this.onAssign(uuid);
      this.setState({ assignRoleModal: false });
    });
  };

  onAssign = async (data) => {
    const { id } = this.props;

    if (data.length > 0) {
      const response = await put({
        url: `${ApiConstant.ASSIGN_ROLE_TO_USER}/${id}/roles`,
        reqObj: data,
        type: "User",
      });

      if (response === 201) {
        createMessage(200, "Roles assigned");
      } else {
        createMessage(response);
      }
      this.onDeleteDone();
    }
    this.setState({ actionDone: true });
  };

  handleAssignRoleModal = () => {
    this.setState({ assignRoleModal: !this.state.assignRoleModal });
  };

  render() {
    const {
      loading,
      userRoleList,
      assignRoleModal,
      selectedRoles,
      isDelete,
      isSingleDelete,
      deleteId,
      actionDone,
    } = this.state;

    let currentCategory = null;

    return (
      <>
        <div className="mt-3 d-flex justify-content-start justify-content-md-end gap-3">
          {actionDone ? (
            <>
              <button
                className="buttonX"
                data-testid={`assign-role`}
                onClick={this.handleAssignRoleModal}
              >
                <i className="bi bi-person-plus me-2" />
                Assign Role(s)
              </button>
              <button
                className="buttonX white"
                data-testid={`unassign`}
                onClick={this.onDeleteRole}
                disabled={selectedRoles.length === 0}
              >
                <i className="bi bi-trash3 me-2" />
                Unassign Role(s)
              </button>
            </>
          ) : (
            <ActivityLoader />
          )}
        </div>
        <div className="mt-3">
          <h4 className="text_color_main">Assigned roles to this user</h4>

          <div className="user-assign-roles-section">
            {!loading && userRoleList && userRoleList.length > 0 ? (
              userRoleList
                .sort((a, b) => {
                  if (a.is_system_role && !b.is_system_role) return -1;
                  if (!a.is_system_role && b.is_system_role) return 1;
                  return 0;
                })
                .map((data, i) => {
                  const title = data.is_system_role
                    ? "System Role"
                    : "User Defined Role";

                  if (title !== currentCategory) {
                    currentCategory = title;
                    return (
                      <React.Fragment key={`title-${title}`}>
                        <h5 className="mt-4">{title}</h5>
                        <div className="role-tile">
                          <input
                            className="form-check-input"
                            data-testid={`role-check ${i}`}
                            type="checkbox"
                            onChange={() => this.rolesToDelete(data.id)}
                          />
                          <p
                            className="role actions"
                            onClick={() =>
                              this.props.history.push({
                                pathname: `${ROLE_DETAILS_PATH}/${data.id}`,
                                state: {
                                  id: data.id,
                                },
                              })
                            }
                          >
                            {data.name}
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  }

                  return (
                    <div className="role-tile">
                      <input
                        className="form-check-input"
                        data-testid={`role-check ${i}`}
                        type="checkbox"
                        onChange={() => this.rolesToDelete(data.id)}
                      />
                      <p
                        className="role actions"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${ROLE_DETAILS_PATH}/${data.id}`,
                            state: {
                              id: data.id,
                            },
                          })
                        }
                      >
                        {data.name}
                      </p>
                    </div>
                  );
                })
            ) : loading ? (
              <SkeletonLoader height={30} time={1} />
            ) : !loading && userRoleList && userRoleList.length === 0 ? (
              <p className="text-center text_color_main no-data-msg mt-2">
                No Roles Assigned{" "}
              </p>
            ) : null}
          </div>
        </div>

        {assignRoleModal && (
          <AssignRoleModal
            onAssign={this.onRoleAssign}
            show={assignRoleModal}
            data={userRoleList}
            handleClose={this.handleAssignRoleModal}
          />
        )}

        {isSingleDelete && (
          <Delete
            title={`${deleteId.name} Roles`}
            show={isSingleDelete}
            onDelete={this.onSingleDelete}
            handleClose={() => this.setState({ isSingleDelete: false })}
          />
        )}

        {isDelete && (
          <Delete
            title={`${selectedRoles.length} Roles`}
            show={isDelete}
            onDelete={this.onDeleteRole}
            handleClose={this.handleDeleteModal}
          />
        )}
      </>
    );
  }
}
