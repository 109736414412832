import React, { createContext, useState } from "react";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);
const CommonContext = createContext(undefined);

function UserProvider({ children }) {
  const [createRole, setCreateRole] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [bookMarks, setBookMarks] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [newPermissions, setNewPermissions] = useState([]);
  const [isassignPermissionModal, setIsassignPermissionModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const Objects = {
    createRole: createRole,
    currentUser: currentUser,
    bookMarks: bookMarks,
    isassignPermissionModal: isassignPermissionModal,
    notificationCount: notificationCount,
    selectedPermissionsContext: selectedPermissions,
    newPermissions: newPermissions,
  };

  const SetFunctions = {
    setCreateRole: setCreateRole,
    setCurrentUser: setCurrentUser,
    setBookMarks: setBookMarks,
    setIsassignPermissionModal: setIsassignPermissionModal,
    setNotificationCount: setNotificationCount,
    setSelectedPermissions: setSelectedPermissions,
    setNewPermissions: setNewPermissions,
  };

  return (
    <UserContext.Provider value={Objects}>
      <UserDispatchContext.Provider value={SetFunctions}>
        <CommonContext.Provider value={{ Objects, SetFunctions }}>
          {children}
        </CommonContext.Provider>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext, CommonContext };
