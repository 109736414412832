/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ThemeContext, { initialThemeState } from "./ThemeContext";

export default function ThemeProvider(props) {
  const [theme, setTheme] = useState(initialThemeState.theme);

  const localStorage = window.localStorage;

  const [documentBody] = useState(
    document.getElementsByTagName("BODY")[0].classList
  );

  const [themeMode] = useState(["dark", "light"]);

  useEffect(() => {
    const savedThemeLocal = localStorage.getItem("globalTheme");

    if (!!savedThemeLocal) {
      setTheme(savedThemeLocal);
    }

    themeMode.forEach(
      (mode) =>
        documentBody.contains(`theme--${mode}`) &&
        documentBody.remove(`theme--${mode}`)
    );

    theme && documentBody.add(`theme--${theme}`);
  }, []);

  useEffect(() => {
    localStorage.setItem("globalTheme", theme);

    themeMode.forEach(
      (mode) =>
        documentBody.contains(`theme--${mode}`) &&
        documentBody.remove(`theme--${mode}`)
    );

    documentBody.add(`theme--${theme}`);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme--${theme}`}>{props.children}</div>
    </ThemeContext.Provider>
  );
}
