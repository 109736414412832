import React from "react";
import { Modal } from "react-bootstrap";

export default function ViewMore({
  show,
  handleClose,
  data,
  fetchKey,
  headKey,
}) {
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>{data[headKey]}</Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ overflowWrap: "anywhere" }} className="view-more-main">
            {data[fetchKey]}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="buttonX outline" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
