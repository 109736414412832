import * as React from "react";
import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { EnhancedTableHead } from "../../components/TableComponent/TableComponent";
import {
  getComparator,
  stableSort,
} from "../../components/TableComponent/TableComponent";
import Layout from "../../components/SideBar/Layout";
import CommonCard from "../../components/CommonCard/CommonCard";
import {
  CREATE_ACCOUNT_PATH,
  ACCOUNT_DETAILS_PATH,
  ACCOUNT_PATH,
} from "../../Router/PathConst";
import AccountMenu from "./AccountMenu";
import { get, put } from "../../utils/apiMethods";
import ApiConstant from "../../utils/apiConstant";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import {
  deleteMessage,
  getCurrentTenant,
  getReadableDate,
  deleteAccountById,
} from "../../utils/common";
import Delete from "../../Modals/Common/Delete";
import { CommonContext } from "../../Context/context";
import MultiSelect from "../../components/MultiSelect/MultiSelect";

const crumbs = [
  {
    title: "Accounts",
    path: ACCOUNT_PATH,
    active: true,
  },
];

const options = [
  { value: "available", label: "Available" },
  { value: "deactivated", label: "Deactivated" },
];

const headCells = [
  {
    id: "account_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "subdomain",
    numeric: false,
    disablePadding: false,
    label: "Dns-subdomain",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "created_timestamp",
    numeric: false,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "modified_timestamp",
    numeric: false,
    disablePadding: false,
    label: "Updated",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default class Accounts extends React.Component {
  static contextType = CommonContext;

  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "account_name",
      selected: [],
      page: 0,
      rowsPerPage: 10,
      UUID: [],
      accountAPIParams: {
        offset: 0,
        limit: 1000,
      },
      accountList: [],
      loading: true,
      isDone: true,
      accountRefList: [],
      selectedAccounts: [],
      isDelete: false,
      searchValue: "",
      selectedFilterOptions: [],
      isDeactivatedAccountVisible: false,
    };
  }

  componentDidMount() {
    if (this.context && this.context.Objects) {
      const { currentUser } = this.context.Objects;
      const { props } = currentUser;

      if (props && "sorting" in props) {
        const sort = JSON.parse(props.sorting);
        if ("user" in sort && sort.account?.length > 0) {
          this.setState({ orderBy: sort.account[0], order: sort.account[1] });
        } else {
          this.setState({ orderBy: "accountname" });
        }
      } else {
        this.setState({ orderBy: "accountname" });
      }
    }
    this.getAccounts();
  }

  // Get Accounts
  getAccounts = async () => {
    const response = await get({
      url: ApiConstant.GET_ACCOUNTS(
        this.state.accountAPIParams?.offset,
        this.state.accountAPIParams?.limit
      ),
    });

    if (response?.length === this.state.accountAPIParams?.limit) {
      this.setState({
        accountAPIParams: {
          ...this.state.accountAPIParams,
          offset:
            this.state.accountAPIParams?.offset +
            this.state.accountAPIParams?.limit,
        },
      });
      this.getAccounts();
    }

    this.setState({
      accountList: [...this.state.accountList, ...(response ?? [])],
      accountRefList: [...this.state.accountRefList, ...(response ?? [])],
      isDone: true,
      loading: false,
    });

    this.toggleFilterHandler();
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;

    const isAsc = orderBy === property && order === "asc";

    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });

    this.onSaveSort(property, isAsc ? "desc" : "asc");
  };

  // save sort
  onSaveSort = (value, order) => {
    const { currentUser } = this.context.Objects;
    const { props } = currentUser;
    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.account = [value, order];
      } else {
        obj.account = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        account: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser } = this.context.Objects;
    const { setCurrentUser } = this.context.SetFunctions;

    const { id, username, email } = currentUser;

    const reqObj = {
      username: username,
      email: email,
      tenantId: getCurrentTenant(),
      props: properties,
    };

    const URL = `${ApiConstant.UPDATE_USER}/${id}`;

    const response = await put({
      url: URL,
      reqObj: reqObj,
    });

    if (response && response.id) {
      if ("sorting" in response.props) {
        setCurrentUser(response);
      }
    }
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = this.state.accountList.map((n) => n.id);
      this.setState({ selected: newSelected, selectedAccounts: newSelected });
      return;
    }
    this.setState({ selected: [], selectedAccounts: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    let newSelected = [];
    const selectedIndex = selected.indexOf(name);
    this.accountToDelete(name);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  accountToDelete = (id) => {
    const { selectedAccounts } = this.state;
    let selectedAccount = [...selectedAccounts];
    const isPresent = selectedAccounts.includes(id);
    const index = selectedAccounts.indexOf(id);

    if (!isPresent) {
      selectedAccount.push(id);
      this.setState({ selectedAccounts: selectedAccount });
    } else {
      selectedAccount.splice(index, 1);
      this.setState({ selectedAccounts: selectedAccount });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  /// Filters
  selectedFilterHandler = (options) => {
    this.setState({ selectedFilterOptions: options });

    this.allFilterHandler(options);
  };

  toggleFilterHandler = () => {
    this.allFilterHandler(this.state.selectedFilterOptions);
  };

  searchFilterHandler = async (search) => {
    await this.setState({ searchValue: search });

    this.allFilterHandler(this.state.selectedFilterOptions);
  };

  checkSearchValue = (accountData, searchValue) => {
    return (
      searchValue === "" ||
      accountData.id.toLowerCase().includes(searchValue.toLowerCase()) ||
      accountData.account_name
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      accountData.email.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  filterDeactivatedAccountHandler = () => {
    const { accountRefList, isDeactivatedAccountVisible, searchValue } =
      this.state;

    return accountRefList.filter((account) => {
      const isStatusMatch =
        isDeactivatedAccountVisible || account.status !== "deactivated";
      const isSearchMatch = this.checkSearchValue(account, searchValue);

      return isStatusMatch && isSearchMatch;
    });
  };

  allFilterHandler = (options) => {
    const { accountRefList, isDeactivatedAccountVisible, searchValue } =
      this.state;

    let newList;

    if (options?.length > 0) {
      newList = accountRefList.filter((account) => {
        if (!isDeactivatedAccountVisible) {
          for (let option of options) {
            if (
              option.value === account?.status &&
              option.value !== "deactivated" &&
              this.checkSearchValue(account, searchValue)
            ) {
              return account;
            }
          }
        } else {
          for (let option of options) {
            if (
              option.value === account?.status &&
              this.checkSearchValue(account, searchValue)
            ) {
              return account;
            }
          }
        }

        return null;
      });
    } else {
      newList = this.filterDeactivatedAccountHandler();
    }

    this.setState({ accountList: newList, page: 0 });
  };

  // Delete
  onDeleteAccount = async () => {
    const { selectedAccounts } = this.state;

    let success = [];
    let failure = [];

    for (let i = 0; i < selectedAccounts?.length; i++) {
      const response = await deleteAccountById(selectedAccounts[i]);

      if (response.status === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
    }

    if (success?.length > 0) {
      deleteMessage(200, `${success?.length} account deleted`);
    }

    if (failure.length > 0) {
      deleteMessage(200, `${failure?.length} account failed to delete`, true);
    }

    this.deleteDone();
  };

  deleteDone = () => {
    this.setState(
      {
        accountList: [],
        accountRefList: [],
        selectedAccounts: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: true,
        accountAPIParams: {
          ...this.state.accountAPIParams,
          offset: 0,
        },
      },
      () => {
        this.getAccounts();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      accountList,
      loading,
      isDone,
      selectedAccounts,
      isDelete,
      isDeactivatedAccountVisible,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="d-flex justify-content-end gap-3">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(`${CREATE_ACCOUNT_PATH}/create`)
            }
          >
            <i className="bi bi-plus-lg me-3" />
            Create Account
          </button>

          {/* <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedAccounts.length === 0}
          >
            <i className="bi bi-trash3 me-3" />
            Delete Accounts
          </button> */}
        </div>

        <CommonCard className="mt-3">
          <div className="d-flex flex-column flex-md-row justify-content-between gap-3">
            <div className="col-md-3">
              <input
                className="input-boxes h-3 w-100"
                type="search"
                placeholder="Search by name, email, or ID."
                onChange={(e) => this.searchFilterHandler(e.target.value)}
              />
            </div>
            <div className="col-md-5 d-flex justify-content-center gap-3">
              <div className="text-right col-7">
                <MultiSelect
                  placeholder="Filter By Status"
                  isMulti
                  closeMenuOnSelect={false}
                  options={options}
                  handleChange={(value) => this.selectedFilterHandler(value)}
                />
              </div>
              <FormControlLabel
                className=""
                label={
                  <span
                    style={{ fontSize: "0.8rem", color: "var(--clr-text-100)" }}
                  >
                    Show Deactivated
                  </span>
                }
                control={
                  <Switch
                    checked={isDeactivatedAccountVisible}
                    onChange={async () => {
                      await this.setState({
                        isDeactivatedAccountVisible:
                          !isDeactivatedAccountVisible,
                      });

                      this.toggleFilterHandler();
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="desktop-table mt-4">
            <TableContainer>
              <Table
                className="tableX"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected?.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={accountList?.length}
                  headCells={headCells}
                />
                {isDone && accountList && accountList?.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(accountList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = this.isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            key={index}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  this.handleClick(event, row.id, row)
                                }
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell> */}
                            <TableCell
                              onClick={() =>
                                row.status !== "deactivated"
                                  ? this.props.history.push(
                                      `${ACCOUNT_DETAILS_PATH}/${row.id}`,
                                      row
                                    )
                                  : undefined
                              }
                              align="left"
                            >
                              <span
                                className={row.status !== "deactivated" && "id"}
                                data-testid={`user-id ${index}`}
                              >
                                {row.account_name ? row.account_name : "-"}
                              </span>
                            </TableCell>

                            <TableCell align="left">{row.subdomain}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">
                              <span
                                className={`text-capitalize ${
                                  row.status === "deactivated"
                                    ? "disable"
                                    : "available"
                                }`}
                              >
                                {row.status}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              {getReadableDate(row.created_timestamp)}
                            </TableCell>
                            <TableCell align="left">
                              {getReadableDate(row.modified_timestamp)}
                            </TableCell>

                            <TableCell align="left">
                              <AccountMenu
                                onComplete={this.deleteDone}
                                id={row.id}
                                title={row.accountname}
                                history={this.props.history}
                                status={row.status}
                                data={row}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            {loading ? (
              <SkeletonLoader height={30} time={1} />
            ) : isDone && accountList?.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                component="div"
                count={accountList?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                showLastButton={true}
                showFirstButton={true}
              />
            ) : !loading && accountList?.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No data found
              </p>
            ) : null}
          </div>
          <div className="mobile-table mt-4">
            {loading ? (
              <SkeletonLoader height={30} time={1} />
            ) : isDone && accountList ? (
              accountList.map((data, i) => {
                const status = data.accountStatus !== 0 ? "Inactive" : "Active";
                return (
                  <div key={i} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">Account Name</div>
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `${ACCOUNT_DETAILS_PATH}/${data.id}`,
                            data
                          )
                        }
                        className="values id"
                      >
                        {data.account_name}
                      </div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">DNS-subdomain</div>
                      <div className="values">{data.subdomain}</div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Email</div>
                      <div className="values">{data.useremail}</div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Created</div>
                      <div className="values">
                        {getReadableDate(data.created_timestamp)}
                      </div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Updated</div>
                      <div className="values">
                        {getReadableDate(data.modified_timestamp)}
                      </div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Actions</div>
                      <div className="text_color_main">
                        <AccountMenu
                          title={data.username}
                          id={data.id}
                          history={this.props.history}
                          status={status}
                          data={data}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading && accountList && accountList?.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No data found
              </p>
            ) : null}
          </div>
        </CommonCard>

        {isDelete && (
          <Delete
            title={`${selectedAccounts?.length} Accounts`}
            show={isDelete}
            onDelete={this.onDeleteAccount}
            handleClose={this.handleDeleteModal}
          />
        )}
      </Layout>
    );
  }
}
