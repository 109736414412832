import NotFound from "../pages/NotFound/NotFound";

import {
  BsBroadcast,
  BsFillPeopleFill,
  BsSpeedometer,
} from "react-icons/bs";

export const routes = [
  {
    path: "/dashboard",
    hashPath: "#/dashboard",
    sideBarDetails: {
      title: "Dashboard",
      icon: <BsSpeedometer className="sideBar-icons" />,
      isSubMenu: false,
    },
  },
  {
    path: "/accounts",
    hashPath: "#/accounts",
    sideBarDetails: {
      title: "Accounts",
      icon: <BsBroadcast className="sideBar-icons" />,
      isSubMenu: false,
    },
  },
  {
    path: "/orders-hub",
    sideBarDetails: {
      title: "Administration",
      icon: <BsFillPeopleFill className="sideBar-icons" />,
      isSubMenu: true,
      subMenu: [
        {
          title: "Users",
          path: "/users",
          hashPath: "#/users",
          Component: "",
        },
        {
          title: "Roles",
          path: "/roles",
          hashPath: "#/role",
          Component: "",
        }
      ],
    },
  },
  {
    path: "*",
    component: NotFound,
  },
];
