import React, { useState, useEffect } from "react";
import { Router } from "./app/Router";
import { getCookieData } from "./app/utils/common";
import { get } from "./app/utils/apiMethods";
import ApiConstant from "./app/utils/apiConstant";

import "./app/assets/css/main.css";
import "./app/assets/css/table.css";
import "./app/assets/css/style.css";
import "./app/assets/css/common.scss";
import "./app/assets/css/details.css";
import "./app/assets/css/details.scss";
import "./app/assets/css/add_edit_section.scss";

import "./app/assets/css/pages/users.scss";

import "react-loading-skeleton/dist/skeleton.css";

export default function App() {
  const [isConfigDataLoading, setIsConfigDataLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("./config.txt");
        if (response.ok) {
          const res = await response.json();
          sessionStorage.setItem("envConfig", JSON.stringify(res));
          getUserData();
        } else {
          console.log("Config File error");
        }
      } catch (err) {
        console.error("Config File error", err);
      } finally {
        setIsConfigDataLoading(true);
      }
    }

    fetchData();
  }, []);

  const getUserData = async () => {
    const user = getCookieData();

    if (user && user.user_id) {
      const URL = `${ApiConstant.GET_USER}/${user?.user_id}`;

      try {
        await get({ url: URL });
      } catch (err) {
        console.error("Error fetching user data", err);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(getUserData, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">{isConfigDataLoading ? <Router /> : "Loading..."}</div>
  );
}
