import React, { Component } from "react";
import Layout from "../../components/SideBar/Layout";

import {
  ALL_RESOURCES_PATH,
  CREATE_ACCOUNT_PATH,
  ACCOUNT_DETAILS_PATH,
  ACCOUNT_PATH,
} from "../../Router/PathConst";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import Properties from "./account_detail_tabs/Properties";
import { get } from "../../utils/apiMethods";
import ApiConstant from "../../utils/apiConstant";
import {
  deleteMessage,
  getReadableDate,
  deleteAccountById,
} from "../../utils/common";
import Delete from "../../Modals/Common/Delete";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import { FaCopy } from "react-icons/fa";
// import AccessToken from "./AccountDetailTabs/AccessToken";
import ActiveInactiveAccountModal from "../../Modals/Accounts/ActiveInactiveAccountModal";
import CommonCard from "../../components/CommonCard/CommonCard";

export default class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleModal: false,
      selectedTab: 0,
      value: 0,
      data: {},
      isData: false,
      roleUUID: [],
      userRoleList: [],
      properties: {},
      isDelete: false,
      loading: true,
      isAllresource: false,
      isTenantCopy: false,
      isProfile: false,
      isActiveInactiveModalVisible: false,
    };
  }

  componentDidMount() {
    this.getAccountDetails();
    this.updateBreadCrumb();
  }

  updateBreadCrumb = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isAllresource
    ) {
      this.setState({ isAllresource: true, isProfile: false });
    } else if (
      this.props.location.state !== undefined &&
      this.props.location.state.isProfile
    ) {
      this.setState({ isProfile: true, isAllresource: false });
    } else {
      this.setState({ isAllresource: false, isProfile: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getAccountDetails();
      });
      this.updateBreadCrumb();
    }
  }

  getAccountDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_ACCOUNT}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (response.AccountStatus === undefined) {
        response.AccountStatus = 0;
      }
      this.setState({ data: response, isData: true, loading: false }, () => {
        // setTimeout(() => {
        //   this.setState({ loading: false,isData: true });
        // }, 1000);
      });
    } else {
      this.setState({ loading: false });
    }
  };

  // end

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // Delete
  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteAccount = async () => {
    const id = this.props.match.params.id;

    const response = await deleteAccountById(id);

    deleteMessage(response, "Account deleted");

    setTimeout(() => {
      this.props.history.push(ACCOUNT_PATH);
    }, 1000);
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onTenantCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isTenantCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isTenantCopy: false });
      }, 1000);
    });
  };

  toggleActiveInactiveModal = () => {
    this.setState({
      isActiveInactiveModalVisible: !this.state.isActiveInactiveModalVisible,
    });
  };

  updateAccountStatus = () => {
    this.getAccountDetails();
  };

  render() {
    const {
      isTenantCopy,
      isAllresource,
      isDelete,
      value,
      data,
      isData,
      isCopy,
      loading,
      isProfile,
      isActiveInactiveModalVisible,
    } = this.state;

    let crumbs = [];

    if (isProfile) {
      crumbs = [
        {
          title: "My profile",
          path: ACCOUNT_DETAILS_PATH,
          active: true,
        },
      ];
    } else {
      crumbs = [
        {
          title: isAllresource ? "All Resources" : "Accounts",
          path: isAllresource ? ALL_RESOURCES_PATH : ACCOUNT_PATH,
          active: false,
        },
        {
          title: "Account Details",
          path: ACCOUNT_DETAILS_PATH,
          active: true,
        },
      ];
    }

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div>
            <SkeletonLoader height={30} time={1} />
          </div>
        ) : !loading && isData ? (
          <>
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 className="details-data-value" data-testid="user-name">
                      {data?.account_name ? data?.account_name : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Account ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : "-"}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "green",
                            fontSize: "12px",
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {" "}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>

                  <div className="details-data-row">
                    <h6 className="details-data-label">DNS subdomain:</h6>
                    <h6 className="details-data-value">
                      {data.subdomain ? data.subdomain : "-"}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Username:</h6>
                    <h6 className="details-data-value">{data.username}</h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Email:</h6>
                    <h6 className="details-data-value">
                      {data ? data.email : "-"}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={`text-capitalize details-data-value 
                          ${
                            data.status !== "available"
                              ? "disable"
                              : "available"
                          }
                        `}
                    >
                      {data.status}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  <button
                    className="buttonX details-button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${CREATE_ACCOUNT_PATH}/${data.id}`,
                        state: {
                          id: data.id,
                        },
                      })
                    }
                  >
                    <i className="bi bi-pencil-square me-2" />
                    Edit Account
                  </button>
                  {/*
                      <button
                        className="buttonX details-button"
                        onClick={() =>
                          exportData(data, `${data.username}-User`)
                        }
                      >
                        <i className="bi bi-cloud-arrow-up me-2" />
                        Export Json
                      </button>
                   */}
                  {/* 
                      <button
                        className="buttonX white details-button"
                        onClick={this.handleDeleteModal}
                      >
                        <i className="bi bi-trash3 me-2" />
                        Delete Account
                      </button>
                    */}

                  <button
                    className="buttonX white details-button"
                    onClick={this.toggleActiveInactiveModal}
                  >
                    <i
                      className={`bi ${
                        data.status === "deactivated"
                          ? "bi-person-check"
                          : "bi-person-dash"
                      } me-2`}
                    />
                    {data.status === "deactivated"
                      ? "Activate Account"
                      : "Deactivate Account"}
                  </button>
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <Tabs
                variant="fullWidth"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={value}
                onChange={this.handleChange}
                aria-label="Account properties table"
              >
                {/* <Tab className="tabX-tab-head" style={{ flex: "1" }} label="Roles" />
                    <Tab className="tabX-tab-head" style={{ flex: "1" }} label="Access Tokens" /> */}
                <Tab
                  className={`tabX-tab-head ${0 === value ? "active" : ""}`}
                  style={{ flex: "1" }}
                  label="Properties"
                />
              </Tabs>

              <SwipeableViews
                axis={useTheme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                {/* <TabPanel value={value} index={0} dir={useTheme.direction}>
                <Roles
                  onAssign={this.onRoleAssign}
                  id={data.id}
                  history={this.props.history}
                />
                </TabPanel>
                <TabPanel value={value} index={1} dir={useTheme.direction}>
                  <AccessToken id={data.id} />
                </TabPanel> */}
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={0}
                  dir={useTheme.direction}
                >
                  <Properties properties={data.props} />
                </TabPanel>
              </SwipeableViews>
            </CommonCard>
          </>
        ) : (
          <h4 className="text_color_main text-center no-data-msg">
            No data found
          </h4>
        )}

        <ActiveInactiveAccountModal
          show={isActiveInactiveModalVisible}
          handleClose={this.toggleActiveInactiveModal}
          accountData={data}
          updateAccountStatus={this.updateAccountStatus}
        />

        {isDelete && (
          <Delete
            title={data.account_name}
            show={isDelete}
            onDelete={this.deleteAccount}
            handleClose={this.handleDeleteModal}
          />
        )}
      </Layout>
    );
  }
}
