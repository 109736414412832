// get method

// if(config ===null){
//   window.location.reload()
// }

let autoReload;
let config;
export let baseURL = "";
let originURL = "";

export const get = async ({ url }) => {
  config = JSON.parse(sessionStorage.getItem("envConfig"));

  autoReload = config && config.REACT_APP_AUTO_RELOAD;
  baseURL =
    config && config.REACT_APP_BASE_API_URL
      ? config.REACT_APP_BASE_API_URL
      : "http://localhost:8080";

  const URL = `${baseURL}${url}`;
  originURL = config && config.REACT_APP_BASE_UI_URL;
  var myHeaders = new Headers();
  var requestOptions = {};
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  if (autoReload === "true") {
    requestOptions = {
      method: "GET",
      header: myHeaders,
      redirect: "manual",
      credentials: "include",
      mode: "cors",
      // Origin: "http://localhost:8080",
      // Referer: "http://localhost:8080"
      Origin: { originURL },
      Referer: { originURL },
    };
    console.log(
      "document cookie api methods",
      document.cookie,
      document.cookie.includes(";")
    );
    var idTokenFound = false;
    if (document.cookie !== undefined && document.cookie.includes(";")) {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookiee = cookies[i].trim();
        if (cookiee.startsWith("idToken")) {
          idTokenFound = true;
        }
      }
    } else {
      const cookies = document.cookie;
      if (document.cookie !== undefined && cookies.startsWith("idToken")) {
        idTokenFound = true;
      }
    }
    if (!idTokenFound) {
      console.log("id token cookie  was not found, redirect to url ", url);
      window.location = URL;
      return;
    } else {
      // console.log("id token cookie  was not found proceed with normal call",requestOptions);
    }
  } else {
    myHeaders.append(
      "X-SERVICE-API-TOKEN",
      config && config.REACT_APP_X_SERVICE_API_TOKEN
    );
    myHeaders.append(
      "X-SERVICE-API-USER",
      config && config.REACT_APP_SERVICE_API_USER
    );
    requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
  }

  return fetch(URL, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 0 && response.type === "opaqueredirect") {
        if (autoReload === "true") {
          console.log("opaqueredirect");
        }
      }
    })
    .catch((err) => {
      console.log("CATCH GET METHOD", "--" + err.message + "--");
      if (
        err.message === "NetworkError when attempting to fetch resource." ||
        err.message === "Failed to fetch"
      ) {
        /*
        if (autoReload === 'true') {
           window.location = url
        }
        */
      }
    });
};

export const post = async ({ url, obj, type, errorFunction }) => {
  config = JSON.parse(sessionStorage.getItem("envConfig"));
  autoReload = config && config.REACT_APP_AUTO_RELOAD;
  baseURL =
    config && config.REACT_APP_BASE_API_URL
      ? config.REACT_APP_BASE_API_URL
      : "http://localhost:8080";
  const URL = `${baseURL}${url}`;
  var requestOptions = {};
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  if (autoReload === "true") {
    requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      header: myHeaders,
      redirect: "manual",
      credentials: "include",
      mode: "cors",
      Origin: { originURL },
      Referer: { originURL },
    };
  } else {
    myHeaders.append(
      "X-SERVICE-API-TOKEN",
      config && config.REACT_APP_X_SERVICE_API_TOKEN
    );
    myHeaders.append(
      "X-SERVICE-API-USER",
      config && config.REACT_APP_SERVICE_API_USER
    );
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
    };
  }
  console.log("config", config);

  console.log("POST method URL", URL);
  return fetch(URL, requestOptions)
    .then((response) => {
      if (type === "v2") {
        return response;
      } else if (response.status === 200 || response.status === 201) {
        return response.json();
      } else if (response.status === 400) {
        return response.json();
      } else {
        return response.status;
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("Error in POST fetch ", err);
      return err;
    });
};

//put method
export const put = async ({ url, reqObj, type }) => {
  config = JSON.parse(sessionStorage.getItem("envConfig"));

  autoReload = config && config.REACT_APP_AUTO_RELOAD;

  baseURL =
    config && config.REACT_APP_BASE_API_URL
      ? config.REACT_APP_BASE_API_URL
      : "http://localhost:8080";

  const URL = `${baseURL}${url}`;

  var myHeaders = new Headers();

  var requestOptions = {};

  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  if (autoReload === "true") {
    requestOptions = {
      method: "PUT",
      body: JSON.stringify(reqObj),
      header: myHeaders,
      redirect: "manual",
      credentials: "include",
      mode: "cors",
      Origin: { originURL },
      Referer: { originURL },
    };
  } else {
    myHeaders.append(
      "X-SERVICE-API-TOKEN",
      config && config.REACT_APP_X_SERVICE_API_TOKEN
    );
    myHeaders.append(
      "X-SERVICE-API-USER",
      config && config.REACT_APP_SERVICE_API_USER
    );
    requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(reqObj),
    };
  }
  console.log("PUT URL", URL);
  return fetch(URL, requestOptions)
    .then((response) => {
      if (type === "v2") {
        return response;
      } else if (response.status === 200 || response.status === 400) {
        if (type === "Job") {
          return response.status;
        } else {
          return response.json();
        }
      } else {
        return response.status;
      }
    })
    .then((data) => {
      return data;
    });
};

// delete method
export const deleteAPI = async ({ url, type }) => {
  config = JSON.parse(sessionStorage.getItem("envConfig"));
  autoReload = config && config.REACT_APP_AUTO_RELOAD;
  baseURL =
    config && config.REACT_APP_BASE_API_URL
      ? config.REACT_APP_BASE_API_URL
      : "http://localhost:8080";
  const URL = `${baseURL}${url}`;
  var myHeaders = new Headers();
  var requestOptions = {};
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  if (autoReload === "true") {
    requestOptions = {
      method: "DELETE",
      header: myHeaders,
      redirect: "manual",
      credentials: "include",
      mode: "cors",
      Origin: { originURL },
      Referer: { originURL },
    };
  } else {
    myHeaders.append(
      "X-SERVICE-API-TOKEN",
      config && config.REACT_APP_X_SERVICE_API_TOKEN
    );
    myHeaders.append(
      "X-SERVICE-API-USER",
      config && config.REACT_APP_SERVICE_API_USER
    );
    requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
  }

  // console.log("DELETE URL",URL);
  return fetch(URL, requestOptions)
    .then((response) => {
      if (type === "v2") {
        return response;
      } else if (response.status === 200) {
        return response.status;
      } else {
        return response.status;
      }
    })
    .then((data) => {
      return data;
    });
};
