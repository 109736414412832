import React, { useEffect, useContext, useState } from "react";
import CurrentTime from "../CurrentTime/CurrentTime";
import Crums from "../../components/BreadCrums/Crums";
import { Base64 } from "js-base64";
import ApiConstant from "../../utils/apiConstant";
import { get, put } from "../../utils/apiMethods";
import { UserContext, UserDispatchContext } from "../../Context/context";
import { USER_DETAILS_PATH } from "../../Router/PathConst";
import HeaderAPIKeyModal from "../../Modals/dashboard/HeaderAPIKeyModal";

import "./Header.css";

export default function HeaderNew(props) {
  const context = useContext(UserContext);
  const setContext = useContext(UserDispatchContext);

  const setCurrentUser = setContext && setContext.setCurrentUser;
  const setBookMarks = setContext && setContext.setBookMarks;
  // const currentUser = useContext(UserContext).currentUser;

  const [anchorEl, setAnchorEl] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [userData, setUserData] = useState({});
  const [propsArray, setPropsArray] = useState({});
  const [propObj, setPropsObj] = useState([]);
  const [stringOfAPIKey, setStringOfAPIKey] = useState("");
  const [isAPIKeyModal, setIsAPIKeyModal] = useState(false);

  function toggleIsAPIKeyModal() {
    setIsAPIKeyModal(!isAPIKeyModal);
  }

  const handleClick = () => {
    setAnchorEl(!anchorEl);
    if (!anchorEl) {
      setSubMenu(false);
    }
  };

  const handleSubMenu = () => {
    setSubMenu(!subMenu);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    if (!mobileMenu) {
      setSubMenu(false);
    }
  };

  const handleLogout = () => {
    window.location.reload();
  };

  useEffect(() => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookiee = cookies[i].trim();
      if (cookiee.startsWith("idToken")) {
        const keyPair = cookiee.split("=");
        if (keyPair && keyPair.length > 1) {
          const array = keyPair[1].split(".");
          const decoded = Base64.decode(array && array.length > 0 && array[1]);
          setUserData(JSON.parse(decoded));
        }
      }
    }
  }, []);

  useEffect(() => {
    // if(setContext && setContext.setCurrentUser){

    const getAPICALl = async () => {
      if (userData !== undefined && "user_id" in userData) {
        const URL = `${ApiConstant.GET_USER}/${userData.user_id}`;

        const response = await get({ url: URL });

        if (response && response !== undefined) {
          setCurrentUser(response);
          setStringOfAPIKey(response.api_key);

          if (response.props && response.props !== undefined) {
            setPropsArray(response.props);

            if ("bookmarks" in response.props) {
              setPropsObj(JSON.parse(response.props.bookmarks));
              setBookMarks(JSON.parse(response.props.bookmarks));
            }
          }
        }

        // return array[0]
      }
    };
    getAPICALl();
  }, [userData, setBookMarks, setCurrentUser]);

  return (
    <>
      <div className="dp-wrapper">
        <div className="dp-section" id="dp-section_content">
          <div className="dp-sction-data-1">
            <div className="dp-section-data-group-1">
              <div
                data-testid="menu-icon"
                onClick={() => props.handleToggle()}
                className="dp-menu-icon"
              />
              <a
                className="link-a"
                href="https://datapelago.io/"
                target="_blank"
                rel="noreferrer"
              >
                <div role="button" className="dp-logo" />
              </a>
              <div className="dp-breadcrumb">
                <Crums items={props.crumbs} history={props.history} />
              </div>
            </div>
          </div>
          <div className="dp-sction-data-2">
            <div className="dp-section-data-group-2">
              <div className="dp-right-icon-group">
                <div className="dp-date-time-marker">
                  <CurrentTime className="dp-date-time-marker" />
                </div>

                <div className="dp-user-name">Hi {userData.given_name}!</div>

                <div
                  onClick={handleClick}
                  data-testid="user-profile"
                  className="dp-profile-icon"
                />

                {anchorEl && (
                  <>
                    <div className="dp-menu-box">
                      <p
                        data-testid="my-profile"
                        onClick={() =>
                          props.history.push({
                            pathname: `${USER_DETAILS_PATH}/${userData.user_id}`,
                            state: {
                              isProfile: true,
                            },
                          })
                        }
                        className="menu"
                      >
                        My Profile
                      </p>
                      {/* <p
                        data-testid="account-settings"
                        onClick={() =>
                          props.history.push({
                            pathname: `${TENANT_DETAILS_PATH}/${userData.tenant_id}`,
                            state: {
                              isProfile: true,
                            },
                          })
                        }
                        className="menu"
                      >
                        Account Settings
                      </p> */}
                      <p className="menu mb-0" onClick={toggleIsAPIKeyModal}>
                        API key
                      </p>
                      {/* <p  onClick={handleLogout} className="menu"> */}
                      {/* <p   className="menu disabled-items">
                        Logout
                      </p> */}
                    </div>
                  </>
                )}
              </div>

              <div onClick={handleMobileMenu} className="dp-right-menu-icon" />

              {mobileMenu && (
                <>
                  <div className="dp-mobile-menu">
                    <div className="dp-mobile-icon">
                      <div className="dp-profile-icon"></div>
                      <div className="dp-user-name">
                        Hi {userData.given_name}!
                      </div>
                    </div>

                    <div className="dp-date-time-marker">
                      Date/Time: <CurrentTime className="dp-date-time-marker" />
                    </div>
                    <p
                      onClick={() =>
                        props.history.push({
                          pathname: `${USER_DETAILS_PATH}/${userData.user_id}`,
                          state: {
                            isProfile: true,
                          },
                        })
                      }
                      className="menu"
                    >
                      My Profile
                    </p>
                    {/* <p
                      onClick={() =>
                        props.history.push({
                          pathname: `${TENANT_DETAILS_PATH}/${userData.tenant_id}`,
                          state: {
                            isProfile: true,
                          },
                        })
                      }
                      className="menu"
                    >
                      Account Settings
                    </p> */}
                    <p className="menu mb-0" onClick={toggleIsAPIKeyModal}>
                      API key
                    </p>
                    {/* <p onClick={handleLogout} className="menu"> */}
                    {/* <p   className="menu disabled-items">
                      Logout
                    </p> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <HeaderAPIKeyModal
        show={isAPIKeyModal}
        handleClose={toggleIsAPIKeyModal}
        apiKey={stringOfAPIKey}
      />
    </>
  );
}
