import { MenuItem, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CommonCard from "../../../components/CommonCard/CommonCard";
import Layout from "../../../components/SideBar/Layout";
import * as Yup from "yup";
import "yup-phone";
import {
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from "../../../Router/PathConst";
import { get, post, put } from "../../../utils/apiMethods";
import ApiConstant from "../../../utils/apiConstant";
import FloatingLabelDropdown from "../../../components/FloatingLabelDropdown/FloatingLabelDropdown";
import { useFormik } from "formik";
import { UserContext, UserDispatchContext } from "../../../Context/context";
import { createMessage, getCurrentTenant } from "../../../utils/common";
import SkeletonLoader from "../../../components/Loader/SkeletonLoader";
import ActivityLoader from "../../../components/Loader/ActivityLoader";

const Status = ["active", "inactive"];

export default function AddAndUpdateRole(props) {
  const allContext = useContext(UserContext);
  const setContext = useContext(UserDispatchContext);

  const createObj = allContext && allContext.createRole;
  const setObj = setContext && setContext.setCreateRole;

  const [type, setType] = useState("add");
  const [data, setData] = useState({});
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [status, setStatus] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);

  const validation = Yup.object().shape({
    name: Yup.string().required("Please enter Role Name"),
    status: Yup.string().required("Please select status"),
  });

  useEffect(() => {
    if (props.match.params.type === "create") {
      setType(props.match.params.type);
    } else {
      setType("edit");
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getRoleData = async () => {
      const id = props.match.params.type;
      const URL = `${ApiConstant.GET_ROLE}/${id}`;
      const response = await get({ url: URL });
      if (response && response !== undefined) {
        setData(response);
        setStatus(response.status);
        setLoading(false);
      }
    };
    if (props.match.params.type !== "create") {
      getRoleData();
    }
  }, [props.match.params.type]);

  useEffect(() => {
    if (createObj !== undefined && "name" in createObj) {
      setData(createObj);
      setObj({});
    }
  }, [createObj, setObj]);

  const formik = useFormik({
    initialValues: {
      name: data.name ? data.name : isFile ? jsonData.name : "",
      description: data.description
        ? data.description
        : isFile
        ? jsonData.description
        : "",
      status: data.status
        ? data.status
        : isFile
        ? jsonData.status.toLowerCase()
        : "active",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  // new
  const getRoleData = async (ID) => {
    const id = ID === undefined ? props.match.params.type : ID;

    const URL = `${ApiConstant.GET_ROLE}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      setData(response);
      setStatus(response.status);
      setLoading(false);
      setActionDone(true);
    }
  };

  const onSubmitCall = async (values) => {
    setActionDone(false);
    if (type === "edit") {
      const URL = `${ApiConstant.UPDATE_ROLE}/${data.id}`;

      const reqObj = {
        name: values.name,
        description: values.description,
        status: values.status,
      };

      const response = await put({
        url: URL,
        reqObj: reqObj,
        type: "v2",
      });

      const newResponse = await response.json();

      if (response.status === 200) {
        createMessage(200, "Role updated");

        setTimeout(() => {
          getRoleData();
          setLoading(true);
        }, 1000);
      } else if (response.status === 400) {
        createMessage(400, newResponse.message);
      } else {
        createMessage(response);
      }
    } else {
      const URL = `${ApiConstant.ADD_ROLE}`;

      const reqObj = {
        name: values.name,
        description: values.description,
        status: values.status,
      };

      const response = await post({
        url: URL,
        obj: reqObj,
        type: "v2",
      });

      const newResponse = await response.json();

      if (response.status === 201) {
        createMessage(200, "Role created");
        setActionDone(true);
        setTimeout(() => {
          props.history.push(ROLE_DETAILS_PATH + "/" + newResponse.id);
        }, 1000);
      } else if (response.status === 400) {
        createMessage(400, newResponse.message);
        setActionDone(true);
      } else {
        createMessage(response.status);
        setActionDone(true);
      }
    }
  };

  const assignPermission = async (id) => {
    const response = await put({
      url: `${ApiConstant.GET_ROLE}/${id}/permissions`,
      reqObj: permissions,
      type: "Role",
    });

    if (response === 201) {
      createMessage(200, "Permission assigned to role");
    } else {
      createMessage(response);
    }
    setLoading(true);
    setTimeout(() => {
      if (type === "edit") {
        setPermissions([]);
        getRoleData(id);
      } else {
        props.history.push(ROLES_PATH);
      }
    }, 1000);
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      console.log("e.target.result", e.target.result, jsonData, isFile, status);
      const response = JSON.parse(e.target.result);

      setJsonData(response);
      setIsFile(true);
    };
  };

  const crumbs = [
    {
      title: "Roles",
      path: ROLES_PATH,
      active: false,
    },
    {
      title: type === "edit" ? "Update Role" : "Create Role",
      path: CREATE_ROLES_PATH,
      active: true,
    },
  ];

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="add-edit-section">
        {type === "edit" && loading ? (
          <SkeletonLoader height={30} time={1} />
        ) : (
          <CommonCard isForm>
            <div className="card-wrapper">
              <div className="form-wrapper">
                <h4 className="text_primary text-left">
                  {type === "edit" ? "Update Role" : "Create Role"}
                </h4>
                {type === "edit" ? null : (
                  <>
                    <div className="text-left my-3">
                      <label className="buttonX" htmlFor="file-import">
                        <i className="bi bi-download"> </i>Import JSON
                      </label>
                      <input
                        id="file-import"
                        type="file"
                        accept=".json"
                        onChange={onJsonUpload}
                      />
                    </div>
                    <p className="text-left text_primary">
                      Or create Role with following details
                    </p>
                  </>
                )}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-wrapper">
                  <div>
                    <TextField
                      autoComplete="off"
                      inputProps={{ "data-testid": "role-name" }}
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      variant="outlined"
                      name="name"
                      label="Role Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </div>

                  <div className="mt-4">
                    <TextField
                      autoComplete="off"
                      inputProps={{ "data-testid": "description" }}
                      style={{ width: "100%" }}
                      variant="outlined"
                      id="description"
                      multiline
                      rows={1}
                      name="description"
                      label="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </div>

                  <div className="mt-4">
                    <FloatingLabelDropdown
                      handleChange={formik.handleChange}
                      value={formik.values.status}
                      name="status"
                      error={formik.touched.status && formik.errors.status}
                      label="Status"
                      options={Status}
                      inputProps={{ "data-testid": "status-select" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Status &&
                        Status.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </FloatingLabelDropdown>
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-end gap-2">
                  {actionDone ? (
                    <>
                      <button
                        className="buttonX"
                        type="submit"
                        data-testid="role-submit"
                      >
                        {type === "edit" ? "Update Role" : "Create Role"}
                      </button>
                      <button
                        className="buttonX white"
                        type="button"
                        onClick={() =>
                          type === "edit"
                            ? props.history.goBack()
                            : props.history.push(ROLES_PATH)
                        }
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <ActivityLoader />
                  )}
                </div>
              </form>
            </div>
          </CommonCard>
        )}
      </div>
    </Layout>
  );
}
