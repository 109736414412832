import React, { useEffect, useState } from "react";
import { getSessionStorage } from "../../utils/storageFunction";

import "./Footer.scss";

export default function Footer() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    const retrieve = getSessionStorage("envConfig");
    const data =
      retrieve !== "undefined" &&
      retrieve !== undefined &&
      JSON.parse(retrieve);
    if (config !== data) {
      setConfig(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="footer-main">
      <p>
        Version 0.0.1
        <span className="ms-2">
          Build No {config && config.REACT_APP_BUILD}
        </span>
        <span className="ms-2">© 2022-2023,</span>
        <a
          className="link-a ms-1"
          target="_blank"
          href="https://datapelago.io/"
          rel="noreferrer"
        >
          DataPelago, Inc.
        </a>
      </p>
    </div>
  );
}
