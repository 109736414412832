import React, { Component } from "react";

import CommonCard from "../../components/CommonCard/CommonCard";
import PieNew from "../../components/PieChart/PieNew";
import Layout from "../../components/SideBar/Layout";
import { DASHBOARD_PATH } from "../../Router/PathConst";
import ApiConstant from "../../utils/apiConstant";
import { get } from "../../utils/apiMethods";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import { getStatusCounts } from "../../utils/common";

import "./dashboard.css";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserUUID: [],
      userList: [],
      isUserDone: false,
      rolesUUID: [],
      roleList: [],
      isRoleDone: false,
      clusterUUID: [],
      accountList: [],
      accountAPIParams: {
        offset: 0,
        limit: 1000,
      },
      isClusterDone: false,
      loading: true,
      notifications: [],
      notificationsRef: [],
      selectedNotifications: [],
      isNotificationDone: false,
      notificationDetails: {},
    };
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      await this.getAccounts();
      await this.getUsers();
      await this.getRoles();
    }
  }

  /// Accounts
  getAccounts = async () => {
    const response = await get({
      url: ApiConstant.GET_ACCOUNTS(
        this.state.accountAPIParams?.offset,
        this.state.accountAPIParams?.limit
      ),
    });

    if (response?.length === this.state.accountAPIParams?.limit) {
      this.setState({
        accountAPIParams: {
          ...this.state.accountAPIParams,
          offset:
            this.state.accountAPIParams?.offset +
            this.state.accountAPIParams?.limit,
        },
      });
      this.getAccounts();
    }

    if (response && response !== undefined) {
      this.setState({
        accountList: [...this.state.accountList, ...(response ?? [])],
        isClusterDone: true,
        isRoleDone: true,
      });
    }
  };

  /// Users
  getUsers = async (id) => {
    const URL = `${ApiConstant.GET_USERS}`;
    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (response.userStatus === undefined) {
        response.status = "Active";
      } else {
        response.status = "Inactive";
      }
      this.setState(
        {
          userList: response,
          isDone: true,
          loading: false,
        },
        () => {
          this.setState({ isUserDone: true });
        }
      );
    }
  };

  /// Roles
  getRoles = async () => {
    const URL = `${ApiConstant.GET_ROLES}`;
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      let resData = response.filter((val) => !val.name.startsWith("SHADOW"));
      this._isMounted &&
        this.setState({ roleList: resData }, () => {
          this.setState({ isRoleDone: true });
        });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      userList,
      roleList,
      accountList,
      isClusterDone,
      isUserDone,
      isRoleDone,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="dashboard-layout">
          <CommonCard>
            <h5 className="card-heading">Account</h5>

            {/* <PieChart /> */}
            {isClusterDone && accountList?.length > 0 ? (
              <PieNew clusterData={accountList} />
            ) : !isClusterDone ? (
              <div className="mt-5">
                <SkeletonLoader height={30} time={1} />
              </div>
            ) : (
              <p className="text_color_main">No Clusters Found</p>
            )}

            {/* <DonutChart/> */}
          </CommonCard>

          <CommonCard>
            <h5 className="card-heading">Administration</h5>
            <div className="dp-admin-prop-cont">
              <div className="dp-table-cont">
                {!isUserDone ? (
                  <SkeletonLoader height={30} time={1} />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            Users
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Total</td>
                          <td
                            data-testid="user-counts"
                            className="text-right admin-td"
                          >
                            {userList && userList.length
                              ? userList.length
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {userList && userList.length
                              ? getStatusCounts(userList, "status", "Active")
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Inactive</td>
                          <td className="text-right admin-td">
                            {userList && userList.length
                              ? getStatusCounts(userList, "status", "Inactive")
                              : "0"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="dp-table-cont">
                {!isRoleDone ? (
                  <SkeletonLoader height={30} time={1} />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            Roles
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Roles</td>
                          <td
                            data-testid="roles-counts"
                            className="text-right admin-td"
                          >
                            {roleList && roleList.length
                              ? roleList.length
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {roleList && roleList.length
                              ? getStatusCounts(roleList, "status", "active")
                              : "0"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Inactive</td>
                          <td className="text-right admin-td">
                            {" "}
                            {roleList && roleList.length
                              ? getStatusCounts(roleList, "status", "inactive")
                              : "0"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </CommonCard>
        </div>
      </Layout>
    );
  }
}

const crumbs = [
  {
    title: "Dashboard",
    path: DASHBOARD_PATH,
    active: true,
  },
];
