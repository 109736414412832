import React, { useState, useEffect } from "react";
import moment from "moment";

export default function CurrentTime(props) {
  const [currentTime, setCurrentTime] = useState(
    moment().format("MM/DD/YYYY, hh:mm:ss A")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("MM/DD/YYYY, hh:mm:ss A"));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={props.className} style={{ marginLeft: "10px" }}>
      {currentTime}
    </div>
  );
}
