import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Input from "../../components/Input/Input";
import ApiConstant from "../../utils/apiConstant";
import { get } from "../../utils/apiMethods";
import ModalLoader from "../Common/ModalLoader";

import "./userModal.scss";

export default class AssignRoleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      isDone: false,
      selectedRoles: [],
      roleRefList: [],
      loading: false,
      systemRoleList: [],
    };
  }

  componentDidMount() {
    this.getUserDefineRoles();
    this.getSystemRole();
  }

  /// Get System Role
  getSystemRole = async () => {
    const URL = ApiConstant.GET_ROLES;

    const response = await get({ url: URL });

    if (response) {
      this.setState({
        systemRoleList: response.filter((item) => item.is_system_role === true),
      });
    }
  };

  /// Get User Define Roles
  getUserDefineRoles = async () => {
    const URL = ApiConstant.GET_ROLES;

    const response = await get({ url: URL });

    const newResponse = response
      .filter((item) => item.is_system_role !== true)
      .filter((item) => !item.name.startsWith("SHADOW"));

    this.setState({
      roleList: newResponse,
      roleRefList: newResponse,
      isDone: true,
    });
  };

  handleSelect = (i, data) => {
    const { roleList } = this.state;
    this.rolesToAssign(data.id);
    const array = [...roleList];
    array[i].selected = !array[i].selected;
    this.setState({ roleList: array });
  };

  handleSystemRoleChange = (data) => {
    const newRoleList = this.state.systemRoleList.map((role) => {
      return {
        ...role,
        selected: data.id === role.id ? true : false,
      };
    });

    this.setState({
      systemRoleList: newRoleList,
    });

    this.rolesToAssign(data.id);
  };

  /// Roles to assign
  rolesToAssign = (id) => {
    const { selectedRoles } = this.state;

    let selectedRole = [...selectedRoles];

    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  onSearchData = (value) => {
    const { roleRefList } = this.state;
    if (value !== "" && value !== undefined) {
      const result = roleRefList.filter((data) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ roleList: result });
    } else {
      this.setState({ roleList: roleRefList });
    }
  };

  onSubmit = () => {
    const { roleList, systemRoleList, selectedRoles } = this.state;
    const result = roleList.filter((item) => item.selected === true);
    const result2 = systemRoleList.filter((item) => item.selected === true);

    this.props.onAssign([...result, ...result2], selectedRoles);
  };

  render() {
    const { show, handleClose } = this.props;
    const { roleList, isDone, systemRoleList } = this.state;

    return (
      <div>
        {isDone ? (
          <Modal size="lg" centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select & Assign Roles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                type="search"
                placeholder="Search Role"
                handleChange={(e) => this.onSearchData(e.target.value)}
              />
              <h6 className="mb-3 fw-bold">System Roles</h6>
              <div className="assign-role" style={{ height: "max-content" }}>
                {systemRoleList && systemRoleList.length > 0 ? (
                  systemRoleList.map((item, i) => {
                    return (
                      <div key={i} style={{ marginBottom: "0.5rem" }}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="systemRole"
                          id={item.name}
                          checked={item.selected}
                          onChange={() => this.handleSystemRoleChange(item)}
                        />
                        <label className="role-div" htmlFor={item.name}>
                          {item.name}
                        </label>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <p>No system roles available.</p>
                )}
              </div>
              <h6 className="mt-4 mb-3 fw-bold">User Defined Roles</h6>
              <div className="assign-role">
                {roleList && roleList.length > 0 ? (
                  roleList.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          marginBottom:
                            roleList.length - 1 !== i ? "0.5rem" : "0",
                        }}
                      >
                        {/* {item && item.isVisible ?( */}
                        <>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            data-testid={`role ${i}`}
                            id={item.name}
                            checked={item.selected}
                            onChange={() => this.handleSelect(i, item)}
                          />
                          <label className="role-div" htmlFor={item.name}>
                            {item.name}
                          </label>
                          {roleList.length - 1 !== i && <hr />}
                        </>
                        {/* ):null} */}
                      </div>
                    );
                  })
                ) : (
                  <p className="text_color text-center  mt-2">No roles found</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="buttonX"
                  data-testid="assign-submit"
                  onClick={this.onSubmit}
                >
                  Assign
                </button>
                <button
                  className="buttonX outline"
                  onClick={this.props.handleClose}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : (
          <ModalLoader centered show={show} />
        )}
      </div>
    );
  }
}
