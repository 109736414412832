import React from "react";
import { Menu, MenuItem } from "@mui/material";
import {
  CREATE_ROLES_PATH,
  ROLE_DETAILS_PATH,
} from "../../../Router/PathConst";
import Delete from "../../../Modals/Common/Delete";
import {
  exportData,
  deleteMessage,
  getRolesPermissionId,
} from "../../../utils/common";

export default function RoleMenu({ id, history, title, onComplete, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setIsDelete(true);
  };
  const closeDelete = () => setIsDelete(false);

  const onDeleteRole = async () => {
    setIsDelete(false);
    
    const response = await getRolesPermissionId(id);

    if (response === 200) {
      deleteMessage(response, "Role deleted");
    } else {
      deleteMessage(response);
    }
    console.log("Delete res", response);
    onComplete();
  };

  return (
    <>
      {isDelete && (
        <Delete
          title={`${title} Role`}
          show={isDelete}
          onDelete={onDeleteRole}
          handleClose={closeDelete}
        />
      )}
      <i
        data-testid="roles-dots"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
        className="bi bi-three-dots-vertical dots"
      ></i>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${ROLE_DETAILS_PATH}/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          View Details
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${CREATE_ROLES_PATH}/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          Edit Role
        </MenuItem>

        <MenuItem onClick={() => exportData(data, `${data.name}-Role`)}>
          Export Json
        </MenuItem>
        {!data.is_system_role && (
          <MenuItem onClick={openDelete}>Delete Role</MenuItem>
        )}
      </Menu>
    </>
  );
}
