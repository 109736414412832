import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { put } from "../../utils/apiMethods";
import ApiConstant from "../../utils/apiConstant";
import { createMessage } from "../../utils/common";

export default function ActiveInactiveAccountModal(props) {
  const [isAPILoading, setIsAPILoading] = useState(false);

  const onStatusChange = async () => {
    setIsAPILoading(true);

    const reqObj = {
      ...props?.accountData,
      status:
        props?.accountData?.status === "deactivated"
          ? "available"
          : "deactivated",
    };

    const URL = `${ApiConstant.UPDATE_ACCOUNT}/${props?.accountData?.id}`;

    const response = await put({
      url: URL,
      reqObj: reqObj,
      type: "v2",
    });

    if (response.status === 200) {
      props.handleClose();
      props.updateAccountStatus();

      const msg =
        props?.accountData?.status === "deactivated"
          ? "Account activated "
          : "Account deactivated ";

      createMessage(200, msg);
    } else {
      props.handleClose();
      createMessage(response);
    }

    setIsAPILoading(false);
  };

  return (
    <Modal
      dialogClassName=""
      centered
      show={props.show}
      onHide={props.handleClose}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props?.accountData?.status === "deactivated"
            ? "Activate"
            : "Deactivate"}{" "}
          Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to{" "}
        {props?.accountData?.status === "deactivated"
          ? "activate"
          : "deactivate"}{" "}
        "{props?.accountData?.account_name}" account?
      </Modal.Body>
      <Modal.Footer>
        <button
          className="buttonX"
          onClick={!isAPILoading ? onStatusChange : undefined}
        >
          {isAPILoading
            ? "Loading..."
            : props?.accountData?.status === "deactivated"
            ? "Activate Account"
            : "Deactivate Account"}
        </button>
        <button className="buttonX white" onClick={props.handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
