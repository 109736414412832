import React, { Component } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Home from "../pages/home/Home";
import Dashboard from "../pages/dashboard/Dashboard";

import Accounts from "../pages/accounts/Accounts";
import AccountDetails from "../pages/accounts/AccountDetails";
import AddAndUpdateAccount from "../pages/accounts/AddAndUpdateAccount";

import Users from "../pages/administration/users/Users";
import UserDetails from "../pages/administration/users/UserDetails";
import AddAndUpdateUser from "../pages/administration/users/AddAndUpdateUser";

import Roles from "../pages/administration/roles/Roles";
import RoleDetails from "../pages/administration/roles/RoleDetails";
import AddAndUpdateRole from "../pages/administration/roles/AddAndUpdateRole";

import NotFound from "../pages/NotFound/NotFound";

import {
  CREATE_ROLES_PATH,
  CREATE_USER_PATH,
  DASHBOARD_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
  USER_DETAILS_PATH,
  USER_PATH,
  CREATE_PERMISSION_PATH,
  ACCOUNT_PATH,
  CREATE_ACCOUNT_PATH,
  ACCOUNT_DETAILS_PATH,
} from "./PathConst";

export default class RouterMain extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path={DASHBOARD_PATH} component={Dashboard} />

          <Route exact path={ROLES_PATH} component={Roles} />

          <Route
            exact
            path={`${CREATE_ROLES_PATH}/:type`}
            component={AddAndUpdateRole}
          />
          <Route
            exact
            path={`${ROLE_DETAILS_PATH}/:id`}
            component={RoleDetails}
          />

          <Route exact path={USER_PATH} component={Users} />
          <Route
            exact
            path={`${CREATE_USER_PATH}/:type`}
            component={AddAndUpdateUser}
          />
          <Route
            exact
            path={`${USER_DETAILS_PATH}/:id`}
            component={UserDetails}
          />

          <Route exact path={ACCOUNT_PATH} component={Accounts} />
          <Route
            exact
            path={`${CREATE_ACCOUNT_PATH}/:type`}
            component={AddAndUpdateAccount}
          />
          <Route
            exact
            path={`${ACCOUNT_DETAILS_PATH}/:id`}
            component={AccountDetails}
          />

          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
