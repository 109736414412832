import { Modal } from "react-bootstrap";
import React, { Component } from "react";
import PropTypes from "prop-types";
import ActivityLoader from "../../components/Loader/ActivityLoader";

export default class Delete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDone: true,
    };
  }

  onClickDelete = () => {
    this.setState({ isDone: false }, () => {
      this.props.onDelete();
    });
  };

  render() {
    const { show, handleClose, title } = this.props;

    return (
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <h5 className="text-center text_color_main">Delete</h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you wish to delete {title}?
        </Modal.Body>
        <Modal.Footer>
          {this.state.isDone ? (
            <>
              <button
                className="buttonX"
                data-testid="modal-delete"
                onClick={this.onClickDelete}
              >
                Delete
              </button>
              <button className="buttonX outline" onClick={handleClose}>
                Cancel
              </button>
            </>
          ) : (
            <ActivityLoader />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

Delete.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};
